import { Component, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { ModalService } from 'src/shared/services/modal/modal.service';
import { LoadingService } from 'src/shared/services/loading/loading.service';
import { ConfigService } from 'src/shared/services/config/config.service';
import { AuthService } from 'src/shared/services/user/auth.service';
import { ToastService } from 'src/shared/services/toast/toast.service';
import { UserService } from 'src/shared/services/user/user.service';
import { OpenUrlService } from 'src/shared/services/open-url/open-url.service';
declare var window: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  freshChatConfig: any
  sub: Subscription | undefined
  showFreshChat: boolean = false
  constructor(
    private goCarModal: ModalService,
    private configService: ConfigService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private userService: UserService,
    private openUrlService: OpenUrlService
  ) { }

  async ngOnInit() {
    try {
      this.loadingService.showLoading()
      const result = await this.configService.getFreshChatConfig()
      this.loadingService.hideLoading()
      this.freshChatConfig = result && result.data
      if (this.freshChatConfig && this.freshChatConfig.isShowFs) {
        this.setupFreshChat()
        this.showFreshChat = this.freshChatConfig.isShowFs;
      }
    } catch (error: any) {
      this.loadingService.hideLoading()
      console.log(error)
      this.toastService.showToastError(error.message)
    }
  }

  setupFreshChat() {
    if (!window['fcWidget'].isInitialized()) {
      this.loadingService.showLoading()
      window['fcWidget'].init({
        token: this.freshChatConfig.token,
        host: this.freshChatConfig.host,
        config: this.freshChatConfig.config || {}
      })

      // listen when init freshchat done
      this.sub = interval(1 * 1000).subscribe(() => {
        if (window['fcWidget'].isLoaded() == true) {
          this.loadingService.hideLoading()
        }
      })
    }

    if (this.authService.isAuthenticated()) {
      const userInfo = this.userService.getUserInfoFromLocalstorage()
      if (userInfo) {
        window['fcWidget'].setExternalId(userInfo.email)
        window['fcWidget'].user.setEmail(userInfo.email);
        window['fcWidget'].user.setFirstName(userInfo.firstName);
        window['fcWidget'].user.setPhone(userInfo.telephone);
      }
    }
  }

  close() {
    this.goCarModal.hideModal()
  }

  gotoFAQ() {
    const faq = this.freshChatConfig.faq
    if (faq) {
      this.openUrlService.openUrl(faq)
    }
  }

  openFreshChat() {
    if (window['fcWidget'].isInitialized()) {
      window['fcWidget'].open()
      window['fcWidget'].show()
    }
  }

  ngOnDestroy() {
    window['fcWidget'].hide()
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }
}
