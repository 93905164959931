import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, throwError } from 'rxjs';
import { environment as config } from '../../environments/environment'
import * as _ from 'lodash';
import { CONSTANTS } from '../common/constants';
import * as CryptoJS from 'crypto-js';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  NOTFOUND_ERROR = { "code": 404, "message": "API Not Found." };
  OBJECT_ERROR = { "code": 400, "message": "No internet connection detected. Please check and try again." };
  UNEXPECTED_ERROR = { "code": 400, "message": "An error occurred, please try again later!" };
  apiURL = '';
  key = '';

  constructor(
    protected http: HttpClient,
    protected alertController: AlertController,
  ) {
    //this.apiURL = config.apiUrl;
    this.key = config.apiEncryptKey;
  }

  private createHttpOptions(params?: any, headersPairs?: any) {
    let myHeaders = {
      'Content-Type': 'application/json',
      observe: 'response'
    } as any;

    if (this.isEnableEncrypt()) {
      myHeaders['x-encrypt'] = 'true';
    }

    if (headersPairs) {
      Object.keys(headersPairs).forEach(key => {
        myHeaders[key] = headersPairs[key];
        if (key === 'Content-Type' && !headersPairs[key]) {
          delete myHeaders[key];
        }
      });
    }
    // dont call localstorage in SSR mode
    const token = localStorage.getItem(CONSTANTS.GOCAR_TOKEN);
    if (token) {
      myHeaders['gocar_token'] = token;
    }

    let headers = new HttpHeaders(myHeaders);

    return { headers, params };
  }

  get<T>(path: string, params?: any): Promise<T> {
    return firstValueFrom(this.http
      .get<T>(this.getFullUrl(path), this.createHttpOptions(params))
      .pipe(
        // Decrypt response body using AES decryption
        map((response: any) => {
          return this.decryptResponse(response);
        }),
        catchError((err) => {
          return this.getError(err);
        })));
  }

  post<T>(path: string, body: any, headersPairs?: any): Promise<T> {
    const encryptedBody = this.encryptRequestBody(body);

    // Send encrypted request to server
    return firstValueFrom(this.http
      .post<T>(this.getFullUrl(path), encryptedBody, this.createHttpOptions(null, headersPairs))
      .pipe(
        // Decrypt response body using AES decryption
        map((response: any) => {
          return this.decryptResponse(response);
        }),
        catchError((err) => {
          return this.getError(err);
        })
      ));
  }

  // encrypt request body
  private encryptRequestBody(body: any) {
    if (!this.isEnableEncrypt()) {
      return body;
    }
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(body), this.key).toString();
    const encryptedBody = { data: ciphertext };
    return encryptedBody;
  }

  private decryptResponse(response: any) {
    if (!this.isEnableEncrypt() || typeof (response?.data) !== 'string') {
      return response;
    }

    try {
      const encryptedResponse = response;
      const bytes = CryptoJS.AES.decrypt(encryptedResponse.data, this.key);
      const plaintext = bytes.toString(CryptoJS.enc.Utf8);
      const decryptedResponse = JSON.parse(plaintext);
      return decryptedResponse;
    } catch (error) {
      return response;
    }
  }

  patch<T>(path: string, body: any): Promise<T> {
    // Encrypt request body using AES encryption
    const encryptedBody = this.encryptRequestBody(body);

    return firstValueFrom(this.http
      .patch<T>(this.getFullUrl(path), encryptedBody, this.createHttpOptions())
      .pipe(
        // Decrypt response body using AES decryption
        map((response: any) => {
          return this.decryptResponse(response);
        }),
        catchError((err) => {
          // Decrypt error response body using AES decryption
          return this.getError(err);
        }))
    );
  }

  delete<T>(path: string): Promise<T> {
    return firstValueFrom(this.http
      .delete<T>(this.getFullUrl(path))
      .pipe(
        // Decrypt response body using AES decryption
        map((response: any) => {
          return this.decryptResponse(response);
        }),
        catchError((err) => {
          return this.getError(err);
        })));
  }

  put<T>(path: string, body: any, headersPairs?: any): Promise<T> {
    const encryptedBody = this.encryptRequestBody(body);

    // Send encrypted request to server
    return firstValueFrom(this.http
      .put<T>(this.getFullUrl(path), encryptedBody, this.createHttpOptions(null, headersPairs))
      .pipe(
        // Decrypt response body using AES decryption
        map((response: any) => {
          return this.decryptResponse(response);
        }),
        catchError((err) => {
          return this.getError(err);
        })));
  }

  getOtherAPI<T>(url: string, params?: any): Promise<T> {
    return firstValueFrom(this.http
      .get<any>(url, this.createHttpOptions(params)));
  }

  postOtherAPI<T>(path: string, body: any, headersPairs?: any): Promise<T> {
    return firstValueFrom(this.http
      .post<T>(path, body, this.createHttpOptions(null, headersPairs))
      .pipe(
        catchError((err) => {
          return this.getError(err);
        })
      ));
  }

  getError(err: HttpErrorResponse) {
    // decrypt error message
    if (this.key && err.error && err.error.data) {
      const bytes = CryptoJS.AES.decrypt(err.error.data, this.key);
      const plaintext = bytes.toString(CryptoJS.enc.Utf8);
      const decryptedResponse = JSON.parse(plaintext);
      const decryptError = { ...err };
      decryptError.error = decryptedResponse;
      err = new HttpErrorResponse({
        error: new Error(decryptError.error.message)
      })
    }

    if (err.status == 401) {
      console.log("status 401 ======>")
      localStorage.removeItem(CONSTANTS.GOCAR_TOKEN);
      localStorage.removeItem(CONSTANTS.USER_INFO);

      err = new HttpErrorResponse({
        error: new Error('Your account is invalid.')
      })
    }

    if (err.status == 403) {
      console.log("status 403 ======>")
      this.alertController?.create({
        header: 'Blocked',
        subHeader: 'Your account is suspended.',
        message: 'Please email hello@gocar.my for more details.',
        buttons: ['OK'],
        mode: 'ios'
      }).then((alert) => {
        alert?.present();
      })

      localStorage.removeItem(CONSTANTS.GOCAR_TOKEN);
      localStorage.removeItem(CONSTANTS.USER_INFO);

      err = new HttpErrorResponse({
        error: new Error('Your account is suspended. Please email hello@gocar.my for more details.')
      })
    }

    if (err.status == 500) {
      err = new HttpErrorResponse({
        error: new Error('Opps, something went wrong, please try again later.')
      })
    }
    return throwError(() => err.error);
  }

  private getFullUrl(path: string): string {
    if (this.apiURL) {
      return `${this.apiURL}/${path}`;
    }

    const { user, carList, reservation } = config.moduleUrls;
    const defaultUrl = carList;

    const prefix = path.split('/')[0];
    let url = '';
    switch (prefix) {
      case 'users':
      case 'auth':
      case 'transactions':
      case 'payment_methods':
      case 'welcome':
        url = user;
        break;
      case 'cars':
      case 'car-types':
      case 'wallets':
      case 'promo':
      case 'pricing':
        url = carList;
        break;
      case 'reservations':
      case 'hold-reservations':
        url = reservation;
        break;
      default:
        url = defaultUrl
        break;
    }
    return path ? `${url}/${path}` : url;
  }

  private isEnableEncrypt() {
    return this.key && (!this.apiURL || this.apiURL === config.gogarage_api);
  }
}
