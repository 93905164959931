import { Injectable } from '@angular/core';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { CONSTANTS } from 'src/shared/common/constants';

@Injectable({
    providedIn: 'root'
})

export class LocationService {

    constructor(
        private geolocation: Geolocation
    ) {
    }

    getCurrentLocation({ timeout, useDefaultLocation = true }: { timeout?: number; useDefaultLocation?: boolean; } = {}): Promise<any> {
        return new Promise(async (res, rej) => {
            const options = {
                maximumAge: 3000,
                enableHighAccuracy: false,
                timeout: timeout || 10000
            };

            try {
                const resp = await this.geolocation.getCurrentPosition(options)
                console.log(resp);
                let position = { lat: resp.coords.latitude.toString(), lng: resp.coords.longitude.toString() }
                localStorage.setItem('userLocation', JSON.stringify(position))
                res(position)
            } catch (error) {
                console.log('Error getting location', error);
                if (!useDefaultLocation) {
                    rej({ message: "Error getting your location. Please make sure turn on GPS." })
                    return
                }
                let storedPosition = localStorage.getItem('userLocation')
                if (storedPosition) {
                    res(JSON.parse(storedPosition))
                    console.log('use location stored');
                } else {
                    res({ lat: CONSTANTS.DEFAULT_LAT, lng: CONSTANTS.DEFAULT_LNG, address: 'GoCar HQ' })
                    console.log('use location default');
                }
            }
        })
    }
}
