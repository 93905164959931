
export class CONSTANTS {
    //regex
    //public static EMAIL_REGEX = /^[a-zA-Z-_.0-9]+@[a-zA-Z_.0-9]+?\.[a-zA-Z]{2,3}$/
    public static EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    public static PASSWORD_REGEX = /^.{8,}$/
    // public static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9\W]+$/ //this is for the letters (both uppercase and lowercase) and numbers validation
    //public static PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-z0-9\W]+$/ //this is for the letters (both uppercase and lowercase) and numbers validation
    public static ONLY_NUMBER_REGEX = /^[0-9]*$/
    public static PHONE_NUMBER = /^\+[1-9]{1}[0-9]{0,14}$/

    // APP Deployment
    public static APP_ID = 'df71cb43'
    public static CHANNEL_NAME = 'master'

    //Reservation offline mode

    public static RESERVATION_KEY = 'gocar-reservation'
    public static RESERVATION_RETURN_KEY = 'gocar-reservation-return'

    // save bluetooth info
    public static BLUETOOTH_DEVICE = 'gocar:device'

    // Time format
    public static BOOKING_TIME_FORMAT = 'YYYY-MM-DD hh:mm A'
    public static BOOKING_DATE_TIME_FORMAT_PRETTY = 'YYYY-MM-DD HH:mm'
    public static RENTALDETAIL_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
    public static BOOKING_DATE_FORMAT = 'YYYY-MM-DD'
    public static BOOKING_HOUR_FORMAT = 'HH:00'
    public static BOOKING_HOUR_MINUTE_FORMAT = 'HH:mm'
    public static RESERVATION_TIME_FORMAT = 'ddd, D MMM YYYY, HH:mm'
    public static RESERVATION_DATE_FORMAT = 'ddd, D MMM YYYY'
    public static RESERVATION_HOUR_FORMAT = 'HH:mm'
    public static DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss z'
    public static HOUR = 1
    public static DAY = 24
    public static MONTH = 24 * 30
    public static PAYMENT_DATE_FORMAT = 'DD MMM YYYY'
    public static RESERVATION_DATE_FILTER_FORMAT = 'ddd, D MMM, HH:mm'

    public static PASSWORD_MINLENGTH = 8
    public static IMAGE_QUALITY = 40
    public static IMAGE_HEIGHT = 600
    public static IMAGE_WIDTH = 800
    public static THUMBNAIL_SIZE = 200
    public static DEFAULT_LAT = 3.1670755
    public static DEFAULT_LNG = 101.6935208
    public static GOCAR_TOKEN = 'gocar_token'
    public static REGISTER_INFO = 'register_info'
    public static FCM_TOKEN = 'fcm_token'
    public static USER_INFO = 'user_info'
    public static COUNT_RESERVATION = 'countReservation'
    public static CONFIG_GLOBAL = 'config_global'

    public static BUILDING_COUNCIL_PARKING = '1e182c80-1c0f-11eb-bb88-cf54a5d9eb5f'
    public static BUILDING_AREA_PARKING = '9dd1c970-b082-11ea-944c-8720c258f051';

    public static MARKER = {
        url: 'https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2FG-icon.png?alt=media&token=f643e62a-8615-40b7-b268-6af4427c5c01',
        //url: './assets/imgs/G-icon.png',
        size: { width: 32, height: 45 }
    }
    public static MARKER_GREY = {
        url: 'https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2FG-icon-grey.png?alt=media&token=1b2240d6-978c-4e5d-aa2f-3c3ec5001656',
        size: { width: 32, height: 45 }
    }

    public static MARKER_SELECTED = {
        url: 'https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2FG-icon-selected.png?alt=media&token=5c2de7d7-da81-41eb-8b55-64927b3a30d7',
        size: { width: 32, height: 39 }
    }


    public static MARKER_H2H = {
        url: 'https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2Fh2h-available.png?alt=media&token=742af200-330f-4994-b415-b2ee04214c83',
        size: { width: 35, height: 56 }
    }
    public static MARKER_GREY_H2H = {
        url: 'https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2Fh2h-booked.png?alt=media&token=714e948a-82b2-4733-8c3e-8636ffc0c023',
        size: { width: 35, height: 56 }
    }

    public static MARKER_SELECTED_H2H = {
        url: 'https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2Fh2h-selected.png?alt=media&token=ae17fa26-c2b3-4fa5-91ff-b930b863e918',
        size: { width: 35, height: 56 }
    }


    public static JS_MARKER = {
        url: './assets/imgs/G-icon.png',
        //scaledSize: new google.maps.Size(32, 45),
    }
    public static JS_MARKER_GREY = {
        url: './assets/imgs/G-icon-grey.png',
        // scaledSize: new google.maps.Size(32, 45),
    }




    public static PAGE_REQUIRED_LOGIN = ['ReservationPage', 'ProfilePage', 'ReferralPage', 'WalletPage', 'AddCardPage', 'MailboxPage']
    public static ONE_HOUR_IN_MILISECONDS = 1 * 60 * 60 * 1000;

    public static DROP_OFF_TYPE = {
        BUILDING: 1,
        AREA: 2,
        COUNCILPARKING: 3
    }

    public static SIGN_UP_TYPE = {
        CHOOSE_SIGN_UP_TYPE: 0,
        SOCIAL: 1,
        EMAIL: 2,
        TC_ACCOUNT: 3,
    }

    public static SIGN_UP_BY = {
        EMAIL: 'EMAIL',
        FACEBOOK: 'FACEBOOK',
        GOOGLE: 'GOOGLE',
        TC_ACCOUNT: 'TC_ACCOUNT'
    }

    public static BOOKING_TYPE = {
        NORMAL: 'normal',
        FREE_FLOATING: 'free_floating',
        GO_VALET: 'go_valet',
        NEW_H2H: 'newh2h',
        SUB_BOOKING: 'sub_booking',
        RESERVATION_PROCESSING: "reservation_processing",
        GOGARAGE: "gogarage"
    }

    public static CAR_DELIVERY_DROP_OFF_TYPE = {
        SAME_PICK_UP: 1,
        BUILDING: 2,
        STREET_PARKING: 3,
        COUNCIL_PARKING: 4
    }

    public static CAR_DELIVERY_STATUS = {
        PENDING: 'pending',
        ASSIGNED: 'assigned',
        CANCELED: 'canceled',
        DONE: 'done',
        NO_CAR: 'no_car'
    }

    public static GOGARAGE_BOOKING_STATUS = {
        PENDING: 'pending',
        APPOINTMENT_CONFIRMED: 'appointment_confirmed',
        APPOINTMENT_CANCELLED: 'appointment_cancelled',
        PICK_UP_VEHICLE: 'pick_up_vehicle',
        VEHICLE_ARRIVED_GARAGE: 'vehicle_arrived_garage',
        VEHICLE_CHECKING: 'vehicle_checking',
        QUOTATION_GENERATED: 'quotation_generated',
        QUOTATION_APPROVED: 'quotation_approved',
        QUOTATION_REJECTED: 'quotation_rejected',
        PROCEED_REPAIR: 'proceed_repair',
        JOB_DONE: 'job_done',
        INVOICE_GENERATED: 'invoice_generated',
        PAYMENT_RECEIVED: 'payment_received',
        CAR_DELIVERED: 'car_delivered',
        COMPLETED: 'completed'
    }

    public static GOGARAGE_PAYMENT_METHOD = {
        PAY_AT_COUNTER: 'Pay At Counter',
        PAY_VIA_APP: 'Pay Via App'
    }

    // public static NATIONALITY = {
    //     MALAY: 0,
    //     NON_MALAY: 1
    // }

    public static NUM_VERIFY_OTP_FAIL = 5

    public static GOEV_ITEM_TYPE = {
        BOOKING: 'book-goev',
        FIND_NEREST: 'find-nerest-ev-charge',
        BENIFIT: 'goev-benifit',
    }

    public static DEFAULT_EXT_SOURCE = {
        google_maps_api_url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBApzusR7FPFL-fe5JSLptS9O-Cha33iZE&libraries=visualization,geometry,places',
        marker_clusterer_url: 'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js',
        fresh_chat_url: 'https://wchat.freshchat.com/js/widget.js',
        recaptcha_url: 'https://www.google.com/recaptcha/api.js'
    }

    public static BOOKING_TYPES: any = {
        SHARING: {
            value: 'sharing',
            name: 'Rental'
        },
        GARAGE: {
            value: 'garage',
            name: 'Service & Repair',
        },
        SUBS: {
            value: "subs",
            name: 'Subscription'
        }
    }

    public static MARKER_NORMAL = {
        icon_available: {
            url: 'https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2FG-icon-2.png?alt=media&token=46602c9b-ef4d-40d7-894e-adb3002d357b',
            size: { width: 32, height: 45 }
        },
        icon_not_available: {
            url: 'https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2FG-icon-grey.png?alt=media&token=1b2240d6-978c-4e5d-aa2f-3c3ec5001656',
            size: { width: 32, height: 45 }
        },
        icon_selected: {
            url: 'https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2FG-icon-selected.png?alt=media&token=5c2de7d7-da81-41eb-8b55-64927b3a30d7',
            size: { width: 32, height: 39 }
        },
        icon_user: {
            url: "./assets/imgs/marker/userlocation.png",
            size: { "width": 32, "height": 45 }
        }
    }

    public static BOOKING_STATUSES: any = {
        // UPCOMING: {
        //     value: 'upcoming',
        //     name: 'Upcoming'
        // },
        CURRENT: {
            value: 'current',
            name: 'Current'
        },
        COMPLETED: {
            value: 'completed',
            name: 'Past'
        },
        // EXPERIED: {
        //     value: 'experied',
        //     name: 'Experied'
        // },
        // CANCELLED: {
        //     value: 'cancelled',
        //     name: 'Cancelled'
        // }
    }

    public static SESSION_PAYMENT = 'payment-success-info'

    public static PAYMENT_METHOD_NEW_TYPE = {
        ONLINE_BANKING: 'online-banking',
        E_WALLET: 'e-wallet',
        INSTALLMENT: 'installment'
    }
    public static TRIP_STATE = {
        UNDEFINED: undefined,
        BEFORE: 'BEFORE_TRIP',
        DURING: 'DURING_TRIP',
        END: 'AFTER_TRIP',
        CANCELLED: "CANCELLED",
        EXPIRED_TRIP: "EXPIRED_TRIP",
        OVERTIME_TRIP: "OVERTIME_TRIP"
    };
    public static ACCIDENT_TYPE = {
        INVOLVES: 'involves',
        NOINVOLVES: 'noinvolves'
    };

    public static CAMERA_SOURCE_TYPE = {
        FILE: 0,
        CAMERA: 1,
        BOTH: 2
    }

    public static CAMERA_TYPE = {
        NORMAL: 1,
        PREVIEW: 2
    }
    public static DEFAULT_ITEM_PER_PAGE = 10;
    public static REPORT_ACCIDENT_STEP = {
        started: -1,
        document: 0,
        thirdparty: 1,
        vehicle: 2,
        accidentdetail: 3,
        done: 4
    };

    public static NATIONALITY = {
        MALAYSIA: { // nric -> DL -> selfie
            value: 'malaysia', name: 'Malaysia',
            items: [
                'Selfie with IC',
                'Picture of IC and Driver’s License',
            ]
        },
        NON_MALAYSIA: { // passport -> DL -> selfie
            value: 'non_malaysia', name: 'Non Malaysia',
            items: [
                'Selfie with Passport',
                'Picture of Passport and International Driving Permit',
            ]
        }
    }
    public static REPORT_STATUS = {
        PENDING: 'PENDING',
        NEW: 'NEW'
    };
    public static CREDIT_CARD_TYPE = {
        VISA: { name: 'Visa', value: 'visa' },
        MASTER_CARD: { name: 'Master Card', value: 'mastercard' }
    };
    public static INSURANCE_COMPANIES = [
        'AIA General Berhad',
        'AIG Malaysia Insurance Berhad',
        'AXA Affin General Insurance Company (Malaysia) Berhad',
        'Allianz General Insurance Company (Malaysia) Berhad',
        'AmGeneral Insurance Berhad',
        'Berjaya Sompo Insurance Berhad',
        'Chubb Insurance Malaysia Berhad',
        'Danajamin Nasional Berhad',
        'Etiqa General Insurance Berhad',
        'Great Eastern General Insurance (Malaysia) Berhad',
        'Liberty Insurance Berhad',
        'Lonpac Insurance Berhad',
        'MPI Generali Insurans Berhad',
        'MSIG Insurance (Malaysia) Bhd',
        'Pacific & Orient Insurance Co. Berhad',
        'Pacific Insurance Berhad',
        'Progressive Insurance Berhad',
        'QBE Insurance (Malaysia) Berhad',
        'RHB Insurance Berhad',
        'Tokio Marine Insurance (Malaysia) Berhad',
        'Tune Insurance Malaysia Berhad',
        'Zurich General Insurance Malaysia Berhad',
        'Others'
    ];
    public static NAVIGATE = {
        WAZE: 'Waze',
        GOOGLE_MAP: 'googlemap'
    }
    public static SUB_BOOKING_STATUS = {
        PENDING_APPROVAL: { value: "pending_approval", name: "Pending Approval", order: 1 },

        PENDING_DOCUMENT: { value: "pending_document", name: "Pending For Document", order: 2 },
        PROCESSING_DOCUMENT: { value: "processing_document", name: "Application has been received", order: 3 },
        INCOMPLETE_DOCUMENT: { value: "incomplete_document", name: "Pending Documents", order: 4, isUnexpected: true },
        APPROVED_DOCUMENT: { value: "approved_document", name: "Approved, pending payment", order: 5 },

        UNSUCCESSFUL: { value: "unsuccessful", name: "Unsuccessful", order: 6, isUnexpected: true },
        RECEIVED_1ST_PAYMENT: { value: "received_1st_payment", name: "Payment received", order: 7 },

        ALLOCATED: { value: "allocated", name: "[Internal] Allocated", order: 8, isInternal: true },
        DELIVERED: { value: "delivered", name: "Delivered", order: 9 },

        PENDING_ONGOING_PAYMENT: { value: "pending_ongoing_payment", name: "Recurring Payment Pending", order: 10 },
        RECEIVED_ONGOING_PAYMENT: { value: "received_ongoing_payment", name: "Recurring Payment Received", order: 11 },

        RETURN_VEHICLE: { value: 'return_vehicle', name: 'Return Vehicle', order: 12 },
        CAR_RETURNED_PENDING_VEHICLE_DEFECT_CHECKING: { value: 'car_returned_pending_vehicle_defect_checking', name: 'Vehicle returned, pending vehicle defect checking', order: 13 },
        CAR_RETURNED_PENDING_FINANCE_CONFIRMATION: { value: 'car_returned_pending_finance_confirmation', name: 'Vehicle returned, pending finance confirmation', order: 14 },

        VEHICLE_RETURNED_PENDING_DEFECT_PAYMENT: { value: 'vehicle_returned_pending_defect_payment', name: '[Internal] Vehicle returned, pending defect payment', order: 15, isInternal: true },
        SUSPENDED: { value: 'suspended', name: '[Internal] Suspended', order: 16, isInternal: true },
        BAD_DEBT: { value: 'bad_debt', name: '[Internal] Bad debt', order: 17, isInternal: true },

        REFUND_SECURITY_DEPOSIT: { value: 'refund_security_deposit', name: '[Internal] Refund security deposit', order: 18, isInternal: true },
        NO_RESPONSE_FROM_USER: { value: 'No_response_from_user', name: '[Internal] No response from user (Cold)', order: 19, isInternal: true },

        ONGOING_PAYMENT_FAIL: { value: "ongoing_payment_fail", name: "Recurring Payment Fail", order: 20, isUnexpected: true, nextOrder: 21 },
        FIRST_PAYMENT_RE_ATTEMPT: { value: 'first_payment_re_attempt', name: '1st payment re-attempt', order: 21, isUnexpected: true, nextOrder: 22 },
        SECOND_PAYMENT_RE_ATTEMPT: { value: 'second_payment_re_attempt', name: '2nd payment re-attempt', order: 22, isUnexpected: true },

        COMPLETED: { value: "completed", name: "Completed", order: 23 },
        CANCELLED: { value: "cancelled", name: "Cancelled", order: 24, isUnexpected: true }
    };
    public static SUB_BOOKING_PAYMENT_TYPE = {
        SECURE_BOOKING_PAYMENT: 'SECURE_BOOKING_PAYMENT', // for payment fee
        FIRST_PAYMENT: 'FIRST_PAYMENT',
        ONGOING_PAYMENT: 'ONGOING_PAYMENT',
        XPRESS_BOOKING_PAYMENT: 'XPRESS_BOOKING_PAYMENT'
    }

    public static ID_NUMBER_REGE = /^[0-9]{6}-[0-9]{2}-[0-9]{4}$/
    public static DRIVING_LICENSE_REGEX = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}(.*)[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/
}