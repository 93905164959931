import { Directive, Input } from '@angular/core';

@Directive({
  selector: 'img',
  host: {
    '(error)': 'onError()',
    '(load)': 'load()',
    '[src]': 'src'
  }
})
export class ImageCachingDirective {

  @Input() src?: string = '';
  @Input() default: string = './assets/imgs/img-default.png';

  constructor() { }

  onError() {
    console.log('onError', this.src);
    this.src = this.default || './assets/imgs/img-default.png';
  }

  load() {
    // this.className = 'image-loaded';
  }
}
