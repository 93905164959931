<app-header title="Help Center" [handleBack]="true" (evenBack)="close()" [showHelp]="false"></app-header>
<ion-content>
  <div class="ion-padding">
    <div class="item-card ion-padding" (click)="gotoFAQ()">
      <div class="w-75 d-flex align-item-center">
        <ion-icon class="mr-md" src="./assets/custom-ion-icons/faq.svg"></ion-icon>
        <strong>GoCar FAQ</strong>
      </div>
      <div class="w-25 ion-text-end">
        <i class='fas fa-angle-right text-16'></i>
      </div>
    </div>
    <div class="item-card ion-padding ion-margin-top-20" (click)="openFreshChat()" *ngIf="showFreshChat">
      <div class="w-75 d-flex align-item-center">
        <ion-icon class="mr-md" src="./assets/custom-ion-icons/livechat.svg"></ion-icon>
        <strong>Live Chat</strong>
      </div>
      <div class="w-25 ion-text-end">
        <i class='fas fa-angle-right text-16'></i>
      </div>
    </div>
  </div>
</ion-content>