<ion-content class="ion-padding">

  <div class="p-md">
    <ion-button size="small" fill="outline" color="primary" (click)="openCamera()">camera</ion-button>
    <ion-button size="small" (click)="pickImages()">photos</ion-button>
    <ion-button size="small" (click)="loading()">loading</ion-button>
  </div>

  <div class="p-md">
    <div>
      Toast button
    </div>
    <ion-button size="small" class="mr-sm" fill="outline" color="primary" (click)="showToast('warning')">warning</ion-button>
    <ion-button size="small" class="mr-sm" (click)="showToast('error')">Error</ion-button>
    <ion-button size="small" (click)="showToast('success')">Success</ion-button>
  </div>

  <!-- <div class="p-md">
    <ion-button (click)="init()">init</ion-button>
    <ion-button color="secondary" fill="outline" (click)="showModal()">create session</ion-button>
    <ion-button [disabled]="true">[BLE] Door Lock</ion-button>
  </div> -->

  <div class="p-md">
    <span>Normal text</span>
    <strong class="ml-md">Bold text</strong>
  </div>

  <div class="p-md">
    <ion-button (click)="popup()">Show PopUp</ion-button>
    <ion-button color="secondary" fill="outline" (click)="showModal()">Show Modal</ion-button>
    <ion-button [disabled]="true">disable</ion-button>
  </div>

  <div class="p-md">
    <ion-checkbox mode="md" labelPlacement="end">check box</ion-checkbox>
  </div>

  <div class="p-md">
    <ion-radio-group mode="md" value="strawberries">
      <ion-radio value="grapes" labelPlacement="end">Grapes</ion-radio>
    </ion-radio-group>
  </div>

  <div class="p-md">
    <ion-input mode="ios" placeholder="input"></ion-input>
  </div>

  <div class="p-md">
    <app-calendar></app-calendar>
  </div>

  <!-- OWEN -->
  <div class="p-md">
    <h1>Button</h1>
    <h2>Primary Button</h2>
    <div class="btn-primary btn">Primary Button</div>
    <div class="disabled btn">Disabled Button</div>

    <h2>Secondary Button</h2>
    <div class="btn-outline btn">Secondary Button</div>
  </div>

  <div class="p-md">
    <h1>Floating Card</h1>
    <ul class="group-cards">
      <li class="card">
        <img src="../../assets/imgs/dummy-car.jpg" width="126" height="112" alt="" />
        <div class="card-info">
          <strong>Nissan Serena S Hybrid</strong>
          <p>from <strong>27.90 / hour</strong></p>
        </div>
        <hr>
        <span class="distance bg-light-green">0.1 km away</span>
        <div class="card-location">
          <strong>GoCar Malaysia,</strong>
          <p>Petaling Jaya</p>
        </div>
      </li>
      <li class="card">
        <img src="../../assets/imgs/dummy-car.jpg" width="126" height="112" alt="" />
        <div class="card-info">
          <strong>Nissan Serena S Hybrid</strong>
          <p>from <strong>27.90 / hour</strong></p>
        </div>
        <hr>
        <span class="distance bg-light-green">0.1 km away</span>
        <div class="card-location">
          <strong>GoCar Malaysia,</strong>
          <p>Petaling Jaya</p>
        </div>
      </li>
    </ul>
    <div class="p-md">
      <h1>Page Header</h1>
      <div class="page-header">
        <i class="fa-solid fa-arrow-left"></i>
        <h2>Forgor Password</h2>
      </div>
    </div>
  </div>

  <div class="ion-padding">
    <h1>Pop Up</h1>
    <h2>Pop Up bottom</h2>
  </div>
  <div class="popup-bottom">
    <div class="bg-popup dummy-height">
      <div class="popup-card">
        <div class="popup-close"><i class="fa-solid fa-xmark"></i></div>
        <h2>How To Locate Your GoCar</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.</p>
      </div>
    </div>
  </div>

</ion-content>