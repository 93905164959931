import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: Object,
  ) { }

  isServerSide() {
    return !isPlatformBrowser(this.platformId);
  }

  createUniqueString() {
    let length = 6
    let charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var randomString = '';
    for (var i = 0; i < length; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  getLocalStorage(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      let data = localStorage.getItem(key);

      return data;
    }

    return null;
  }

  removeLocalStorage(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      let data = localStorage.removeItem(key);

      return data;
    }

    return null;
  }

  setLocalStorage(key: string, data: any) {
    if (!key) {
      return;
    }

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!data) {
      return localStorage.setItem(key, '');
    }

    localStorage.setItem(key, data);
  }

  fileToBase64(file: any) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  public thumbnailify(fileUri: string, targetSize: number, callback: Function) {
    var img = new Image();

    img.onload = () => {
      var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");

      canvas.width = canvas.height = targetSize;

      ctx?.drawImage(
        img,
        width > height ? (width - height) / 2 : 0,
        height > width ? (height - width) / 2 : 0,
        width > height ? height : width,
        width > height ? height : width,
        0, 0,
        targetSize, targetSize
      );
      callback(canvas.toDataURL());
    };

    img.src = fileUri;
  }

  getCenterPolygon(points: any) {
    var bounds = new google.maps.LatLngBounds();
    points.forEach((point: any) => {
      bounds.extend(point)
    });
    return bounds.getCenter()
  }

  getBufferPolygon(polygon: google.maps.Polygon, padding = 10) {
    let paths = polygon.getPath();
    let newPath = [];
    let center = this.getCenterPolygon(paths)
    for (var i = 0; i < paths.getLength(); i++) {
      newPath[i] = google.maps.geometry.spherical.computeOffset(center,
        padding + google.maps.geometry.spherical.computeDistanceBetween(center, paths.getAt(i)),
        google.maps.geometry.spherical.computeHeading(center, paths.getAt(i)));
    }
    // buffer the polygon
    return {
      paths: newPath,
      strokeColor: '#37BC9B',
      strokeWeight: 1,
      fillColor: '#37BC9B',
      fillOpacity: 0.6,
      editable: false
    }
  }

  rotationImage(base64Str: string, degrees: number) {
    return new Promise((res, rej) => {
      try {
        var canvas = document.createElement('canvas');
        var canvasContext = canvas.getContext("2d");
        var img = new Image();
        img.onload = function () {
          if ((degrees / 90) % 2 === 0) {
            canvas.width = img.width;
            canvas.height = img.height;
          } else {
            canvas.width = img.height;
            canvas.height = img.width;
          }

          switch (degrees) {
            case 90:
              canvasContext?.translate(canvas.width, 0);
              break;
            case 180:
              canvasContext?.translate(canvas.width, canvas.height);
              break;
            case 270:
              canvasContext?.translate(0, canvas.height);
              break;
          }

          canvasContext?.rotate(degrees * Math.PI / 180);
          canvasContext?.drawImage(img, 0, 0);

          res(canvas.toDataURL());
        };
        img.src = base64Str;
      } catch (error) {
        rej(false)
      }
    })
  }

  getFullName(user: { firstName: string; lastName: string; }) {
    if (!user) {
      return '';
    }

    let name = user.firstName || '';
    if (!user.lastName) {
      return name;
    }

    name += ' ' + user.lastName;
    return name ? name.trim() : '';
  }

  getPaymentTypeOfTransaction(payment: any) {
    var type = payment.type
    var typeFee = payment.typeFee
    let string = ''
    switch (type) {
      case 'new':
        string = 'Car Booking'
        if (typeFee > 0)
          string = payment.note

        break;
      case 'extend':
        string = 'Booking Extend'
        if (typeFee > 0)
          string = payment.note

        break;
      case 'refund':
        string = 'Booking Refund'
        if (typeFee > 0)
          string = payment.note

        break;
      default:
        break;
    }
    return string;
  }

  showConstantName(constants: { [x: string]: any; }, value: any) {
    if (!constants || !value) {
      return '';
    }
    const arrays = Object.keys(constants).map(key => constants[key]);
    const item = arrays.find(item => item.value === value);

    return item && item.name || '';
  }

  /*
   * Get distance from point to point by kilometers
   * @param point1 lat: number, lng: number
   * @param point2 lat: number, lng: number
  */
  public getDistanceFromPointToPoint(point1: any, point2: any): any {
    var lat = [point1.lat, point2.lat]
    var lng = [point1.lng, point2.lng]
    var R = 6378137;
    var dLat = (lat[1] - lat[0]) * Math.PI / 180;
    var dLng = (lng[1] - lng[0]) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat[0] * Math.PI / 180) * Math.cos(lat[1] * Math.PI / 180) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round(d).toFixed(2);
  }
}
