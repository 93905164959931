import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APP_ROUTES } from '../common/app-routes';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../services/user/auth.service';
import { ToastService } from '../services/toast/toast.service';
import { NavService } from '../services/nav/nav.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private navService: NavService,
    private gocarToast: ToastService,
    @Inject(PLATFORM_ID) private platformId: Object) {

  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('CanActivate called');

    let isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      return true
    } else {
      this.authService.clearUserSession();
      this.gocarToast.showToastError('Please login/ sign up to access');
      this.navService.goToLoginPage()
      return false;
    }
  }

}
