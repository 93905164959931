import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { NavService } from '../nav/nav.service';
import { AuthService } from '../user/auth.service';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import { ModalService } from '../modal/modal.service';
import { APP_ROUTES } from 'src/shared/common/app-routes';
export interface OpenUrlOption {
  url: string;
  requiredLogin?: boolean;
  isShowIab?: boolean;
  isShowIframe?: boolean;
  name: string
}
@Injectable({
  providedIn: 'root'
})
export class OpenUrlService {
  iabOption: InAppBrowserOptions = {
    fullscreen: 'no',
    toolbar: 'yes',
    toolbarposition: 'top',
    hidden: 'no',
    location: 'yes',
    clearsessioncache: 'yes',
    clearcache: 'yes',
    cleardata: 'no', // set NO if set YES then current localstorage will removed
    zoom: 'no',
    footer: 'no',
    toolbarcolor: '#09A77E',
    // navigationbuttoncolor: '#ffffff',
    closebuttoncolor: '#ffffff',
    hideurlbar: 'yes',
    hidenavigationbuttons: 'yes',
    closebuttoncaption: 'Close'
  }
  constructor(
    private iab: InAppBrowser,
    private platform: Platform,
    private navService: NavService,
    private authService: AuthService,
    private spinnerDialog: SpinnerDialog,
    private modalService: ModalService
  ) {
    if (this.platform && this.platform.is('ios')) {
      this.iabOption.location = 'no'
    }
  }

  createIab(url: string) {
    return this.iab.create(url, '_blank', this.iabOption);
  }

  openUrl(data: OpenUrlOption) {
    if (data.requiredLogin && !this.authService.isAuthenticated) {
      this.navService.goToLoginPage
    } else {
      if (data.isShowIframe) {
        // TODO
        // this.openIframe(data.url, data.name)
      } else {
        this.openIab(data.url)
      }
    }
  }

  private openIab(url: string) {
    let browser = this.iab.create(url, '_blank', this.iabOption);

    // show loading
    this.spinnerDialog.show('', 'Redirecting...', true);

    browser.on('loadstop').subscribe(result => {
      console.log("browser loadstop =======> ", result)
      if (result && result.url.indexOf('/done.html') != -1) {
        browser.close()
      }
      if (result && result.url.indexOf('/back.html') != -1) {
        browser.close()
      }
      this.spinnerDialog.hide();
    })

    browser.on('loadstart').subscribe(result => {
      console.log("browser loadstart =======> ", result)
      if (result && result.url.indexOf('/done.html') != -1) {
        browser.close()
      }
      if (result && result.url.indexOf('/back.html') != -1) {
        browser.close()
      }

      // handle for ev website
      if (result && result.url.indexOf('/is_ev.html') != -1) {
        browser.close()
        this.navService.navigate(APP_ROUTES.RESERVATIONS + '/search', { isEv: true })
      }
    })

    browser.on('exit').subscribe(async result => {
      console.log("iab exit =======> ", result)
      this.spinnerDialog.hide();
    })

    browser.on('loaderror').subscribe((err) => {
      console.log("iab loaderror =======> ", err)
      this.spinnerDialog.hide();
    })
  }
}
