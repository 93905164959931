import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/shared/services/modal/modal.service';

@Component({
  selector: 'app-popup-header',
  templateUrl: './popup-header.component.html',
  styleUrls: ['./popup-header.component.scss'],
})
export class PopupHeaderComponent implements OnInit {
  @Input() title: string = '';

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit() { }

  close() {
    this.modalService.hideModal()
  }
}
