import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from 'src/shared/common/constants';
import { ModalService } from 'src/shared/services/modal/modal.service';

@Component({
  selector: 'app-popup-rental-option',
  templateUrl: './popup-rental-option.component.html',
  styleUrls: ['./popup-rental-option.component.scss'],
})
export class PopupRentalOptionComponent implements OnInit {
  BOOKING_TYPE = CONSTANTS.BOOKING_TYPE
  tripTypeSelected: string = this.BOOKING_TYPE.NORMAL

  rentalOptions: any = []
  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.rentalOptions = this.modalService.get('rentalOptions') || []
  }

  next() {
    this.modalService.hideModal(this.tripTypeSelected)
  }

  close() {
    this.modalService.hideModal(null)
  }
}
