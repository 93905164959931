import { Component } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { APP_ROUTES } from 'src/shared/common/app-routes';
import { FaqComponent } from 'src/shared/components/faq/faq.component';
import { ConfigService } from 'src/shared/services/config/config.service';
import { FaceApiService } from 'src/shared/services/face-api/faceapi.service';
import { GlobalService } from 'src/shared/services/global.service';
import { ModalService } from 'src/shared/services/modal/modal.service';
import { NavService } from 'src/shared/services/nav/nav.service';
import { ToastService } from 'src/shared/services/toast/toast.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { NetworkService } from 'src/shared/native_services/network/network.service';
import { NetWorkStatus } from 'src/shared/common/network-status.enum';
import { LoadingService } from 'src/shared/services/loading/loading.service';
import { UtilsService } from 'src/shared/services/utils/utils.service';
import { CONSTANTS } from 'src/shared/common/constants';
import { FiltersService } from 'src/shared/services/fiters/filters.service';
import { FcmService } from 'src/shared/native_services/FCM/fcm.service';

export enum Tabs {
  PROMO = 'promo',
  WALLET = 'wallet',
  HOME = 'home',
  MY_BOOKING = 'my_booking',
  HELP = 'help',
  USER = 'user',
}
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  Tabs = Tabs
  currentTab: Tabs = Tabs.HOME

  totalRes: number = 0
  counter: number = 0

  NetWorkStatus = NetWorkStatus
  network: string = NetWorkStatus.ONLINE
  constructor(
    private navService: NavService,
    private modalService: ModalService,
    private platform: Platform,
    private statusBar: StatusBar,
    private toastService: ToastService,
    private router: Router,
    private configService: ConfigService,
    private globalService: GlobalService,
    private faceApiService: FaceApiService,
    private networkService: NetworkService,
    private loadingService: LoadingService,
    private utilsService: UtilsService,
    private filtersService: FiltersService,
    private fcmService: FcmService
  ) {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log("NavigationStart: ", event)
      }

      if (event instanceof NavigationEnd) {
        console.log("NavigationEnd: ", event)
        this.handleTabSelected(event.url)
      }
    });
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#00c09e');
      this.initializeApp()
      this.getConfigGlobal()
      this.globalService._totalRes.subscribe((num: number) => {
        console.log("NUMMMMMMM -----> ", num)
        this.totalRes = num
      })
      this.networkService.networkStatus_.subscribe(result => {
        console.log("networkStatus_ ===> ", result)
        this.network = result
      })
    })
  }

  initializeApp() {
    this.backButtonEvent()
    this.networkService.listenerNetworkStatus()
    // init face api
    this.faceApiService.initFaceApi()
  }

  async getConfigGlobal() {
    try {
      const result = await this.configService.getGlobalConfig();
      if (result && result.data) {
        const configGlobal = result.data
        this.globalService.setConfigGlobal(configGlobal);
        this.utilsService.setLocalStorage(CONSTANTS.CONFIG_GLOBAL, JSON.stringify(configGlobal));
        this.filtersService.setDefaultBookingTime(configGlobal.defaultBookingTime)
        // init FCM
        this.fcmService.init()
      }
    } catch (error: any) {
      console.log("getConfigGlobal ERROR ========>: ", error);
      let cache = this.utilsService.getLocalStorage(CONSTANTS.CONFIG_GLOBAL);
      if (cache) {
        const configGlobal = JSON.parse(cache)
        this.globalService.setConfigGlobal(configGlobal);
        this.filtersService.setDefaultBookingTime(configGlobal.defaultBookingTime)
        // init FCM
        this.fcmService.init()
      }
    }
  }

  backButtonEvent() {
    //TODO: handle hardware back button on android
    let t = this
    document.addEventListener('backbutton', async function (event) {
      // event.preventDefault();
      // event.stopPropagation();
      // t.toastService.showToast("Click Back Button.")

      // close modal
      try {
        const element = await t.modalService.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
        console.log(error);
      }

      // close component/page
      console.log("close component/page ====> ", t.router.url)
      console.log("counter ====> ", t.counter)
      if (t.router.url === '/home') {
        if (t.counter == 0) {
          t.counter++;
          t.toastService.showToast('Double tap to exit.')
          setTimeout(() => { t.counter = 0 }, 3000);
        } else {
          (navigator as any)['app'].exitApp();
        }
      } else {
        if (t.router.url == APP_ROUTES.BOOKINGS.HOME
          || t.router.url == APP_ROUTES.WALLET
          || t.router.url == APP_ROUTES.PROMO
          || t.router.url == APP_ROUTES.RESERVATIONS) {
          t.navService.goHome()
        } else {
          t.navService.back()
        }
      }

      t.loadingService.hideLoading()
    }, false);
  }

  clickTabs(tab: string) {
    switch (tab) {
      case Tabs.PROMO:
        this.navService.navigateAndReplace(APP_ROUTES.PROMO)
        break;
      case Tabs.WALLET:
        this.navService.navigateAndReplace(APP_ROUTES.WALLET);
        break;
      case Tabs.HOME:
        this.navService.navigateAndReplace(APP_ROUTES.HOME)
        break;
      case Tabs.MY_BOOKING:
        this.navService.navigateAndReplace(APP_ROUTES.BOOKINGS.HOME)
        break;
      case Tabs.USER:
        this.navService.navigateAndReplace(APP_ROUTES.USER)
        break;
      default:
        break;
    }
  }

  handleTabSelected(url: string) {
    const urls = url && url.split('/')
    const url1 = urls.length > 0 && urls[1] && urls[1].split("?") || ['']
    console.log(url1[0])

    switch (url1[0]) {
      case APP_ROUTES.PROMO:
        this.currentTab = Tabs.PROMO
        break;
      case APP_ROUTES.WALLET:
        this.currentTab = Tabs.WALLET
        break;
      case APP_ROUTES.BOOKINGS.HOME:
        this.currentTab = Tabs.MY_BOOKING
        break;
      case APP_ROUTES.USER:
        this.currentTab = Tabs.USER;
        break;
      default:
        this.currentTab = Tabs.HOME
        break;
    }
  }

  isShowFAQicon() {
    return this.router.url === '/home';
  }
  showFAQ() {
    this.modalService.showModal(FaqComponent);
  }
}
