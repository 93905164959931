import { EventEmitter, Injectable } from '@angular/core';
import { Camera, CameraOptions, DestinationType, EncodingType, MediaType } from '@awesome-cordova-plugins/camera/ngx';
import { CONSTANTS } from 'src/shared/common/constants';
import { GlobalService } from 'src/shared/services/global.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Observable } from 'rxjs/internal/Observable';
import { NetWorkStatus } from 'src/shared/common/network-status.enum';

@Injectable({
    providedIn: 'root'
})

export class NetworkService {
    networkStatus_: EventEmitter<'connected' | 'disconnected'> = new EventEmitter()
    public appIsOnline$: Observable<boolean> | undefined;
    constructor(
        private network: Network
    ) {
        // this.network.onChange().subscribe(status => {
        //     console.log("listenerNetworkStatus =====> ", status)
        //     this.networkStatus_.emit(status)
        // })
    }

    listenerNetworkStatus() {
        this.network.onChange().subscribe(status => {
            console.log("listenerNetworkStatus =====> ", status)
            this.networkStatus_.emit(status)
        })
    }
}
