import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { UserService } from 'src/shared/services/user/user.service';
import { CONSTANTS } from 'src/shared/common/constants';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { NavService } from 'src/shared/services/nav/nav.service';
import { GlobalService } from 'src/shared/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class LocalPushNotificationService {
  configFCM: any

  constructor(
    private localNotifications: LocalNotifications,
    private navService: NavService
  ) {
   }

  // TODO
  async setSchedules(schedules: any) {
    const getIds = await this.localNotifications.getIds()
    const getScheduledIds = await this.localNotifications.getScheduledIds()
    const getTriggeredIds = await this.localNotifications.getTriggeredIds()
    const getAll = await this.localNotifications.getAll()

    console.log("-----11 getIds -------", getIds)
    console.log("-----11 getScheduledIds -------", getScheduledIds)
    console.log("-----11 getTriggeredIds -------", getTriggeredIds)
    console.log("-----11 getAll -------", getAll)

    if (schedules && schedules.length > 0) {
      schedules.forEach((schedule: any) => {
        console.log("schedule 121212------>", schedule)
        this.localNotifications.schedule(schedule);
      });
    }
  }
}