import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as config } from "src/environments/environment";
import { BaseService } from "../base.service";
import { ApiResponse } from "src/shared/models/common";
import { CONSTANTS } from "src/shared/common/constants";
import { UtilsService } from "../utils/utils.service";
import { AlertController } from "@ionic/angular";
import { HoldRegistrationRes, SmsVerifyStatus, User } from "src/shared/models/user";

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseService {
    prefix = 'users';

    constructor(
        public override http: HttpClient,
        public override alertController: AlertController,
        private utilsService: UtilsService
    ) {
        super(http, alertController);
        //this.apiURL = config.apiUrl;
    }

    getUserInfo(): Promise<ApiResponse<any>> {
        return this.get(`${this.prefix}/info`);
    }

    update(body: any): Promise<ApiResponse<any>> {
        return this.put(`${this.prefix}`, body);
    }

    updateNewDocument(body: any): Promise<ApiResponse<any>> {
        return this.put(`${this.prefix}/update-new-document`, body);
    }

    removeUser(): Promise<ApiResponse<boolean>> {
        return this.post(`${this.prefix}/remove`, {});
    }

    createOrUpdateFCMToken(data: any): Promise<ApiResponse<any>> {
        return this.post(`${this.prefix}/fcm-tokens/create`, data);
    }

    deleteFCMToken(deleteTokenPayload: any): Promise<ApiResponse<any>> {
        return this.post(`${this.prefix}/fcm-tokens/delete`, deleteTokenPayload);
    }

    getUserInfoFromLocalstorage(): any | null {
        const str = this.utilsService.getLocalStorage(CONSTANTS.USER_INFO);
        if (str) {
            return JSON.parse(str)
        } else {
            return null;
        }
    }

    updateUserInfoToLocalStorage(userInfo: Partial<User>): void {
        const user = this.getUserInfoFromLocalstorage();
        if (!user) {
            return;
        }

        const newUser = { ...user, ...userInfo };
        this.utilsService.setLocalStorage(CONSTANTS.USER_INFO, JSON.stringify(newUser));
    }

    isExist(payload: { email?: string, telephone?: string, signupType?: string }): Promise<boolean> {
        return this.post<ApiResponse<boolean>>(`${this.prefix}/exists`, payload)
            .then(res => res.data);
    }

    public holdRegistration(user: Partial<User>): Promise<ApiResponse<HoldRegistrationRes>> {
        return this.post(`${this.prefix}/hold-registration`, user);
    }

    // public createHoldRegistration(user: Partial<UserInfo>): Promise<ApiResponse<HoldRegistrationRes>> {
    //     return this.post(`${this.prefix}/create-hold-registration`, user);
    // }

    public updateHoldRegistration(user: Partial<User>): Promise<ApiResponse<string>> {
        return this.put(`${this.prefix}/hold-registration`, user);
    }

    public verifyOTP(phone: string, pincode: string): Promise<ApiResponse<SmsVerifyStatus>> {
        return this.get(`${this.prefix}/otp/verify/${phone}/${pincode}`);
    }

    public createHoldRegistration(user: Partial<User>): Promise<ApiResponse<HoldRegistrationRes>> {
        return this.post(`${this.prefix}/create-hold-registration`, user);
    }

    // public updateDocument(user: Partial<UserInfo>): Promise<ApiResponse<UserInfo>> {
    //     return this.put(`${this.prefix}/documents`, user);
    // }

    public generateOTP(body: { phone: string; signUpType?: string }): Promise<ApiResponse<SmsVerifyStatus>> {
        return this.post(`${this.prefix}/otp/generate`, body);
    }

    async requestResetPassword(email: string): Promise<ApiResponse<boolean>> {
        return await this.post(`${this.prefix}/request-reset-password`, { email });
    }

    async verifyResetPassword(email: string, otp: string): Promise<ApiResponse<boolean>> {
        return await this.post(`${this.prefix}/verify-reset-password`, { email, otp });
    }

    async resetPassword(email: string, otp: string, newPassword: string, reNewPassword: string): Promise<ApiResponse<boolean>> {
        return await this.post(`${this.prefix}/reset-password`, { email, otp, newPassword, reNewPassword });
    }

    changePassword(currentPassword: string, newPassword: string, confirmNewPassword: string): Promise<ApiResponse<boolean>> {
        return this.post(`${this.prefix}/change-password`, { currentPassword, newPassword, confirmNewPassword });
    }

    getDescriptor(): Promise<ApiResponse<any>> {
        return this.get(`${this.prefix}/descriptor`);
    }

    checkUserVerified(): Promise<ApiResponse<boolean>>{
        return this.post(`${this.prefix}/check-user-verified`, {});
    }

    // public verifyOTP(telephone: string, otp: string): Promise<ApiResponse<OTPStatus>> {
    //     return this.get(`${this.prefix}/otp/verify/${telephone}/${otp}`, null);
    // }
}
