import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from 'src/shared/models/common';
import { AlertController } from '@ionic/angular';
import { ServiceHightLight } from 'src/shared/models/garage';
import { CubabeliPolicy } from 'src/shared/models/cubabeli';

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends BaseService {
  prefix = 'configs'
  constructor(
    public override http: HttpClient,
    public override alertController: AlertController,
  ) {
    super(http, alertController);
  }

  getHomeServices(): Promise<ApiResponse<any>> {
    return this.get(`${this.prefix}/home-services`)
  }

  getPromotions(): Promise<ApiResponse<any>> {
    return this.get(`${this.prefix}/home-promotion`)
  }

  getPaymentGatewayConfig(mode: string): Promise<ApiResponse<any>> {
    return this.get(`${this.prefix}/payment?mode=${mode}`);
  }

  getGlobalConfig(): Promise<ApiResponse<any>> {
    return this.get(`${this.prefix}/global`);
  }

  getFreshChatConfig(): Promise<ApiResponse<any>> {
    return this.get(`${this.prefix}/fresh-chat`);
  }

  getLongTermPricingPackages(): Promise<ApiResponse<any[]>> {
    return this.get(`${this.prefix}/long-term-pricing-packages`);
  }

  getPointPackages(): Promise<ApiResponse<any>> {
    return this.get(`${this.prefix}/point-packages`)
  }

  getPointPackagesSuggest(body: any): Promise<ApiResponse<any>> {
    return this.post(`${this.prefix}/point-packages-suggest`, body)
  }

  getFreeFloatingConfig(): Promise<ApiResponse<any>> {
    return this.get(`${this.prefix}/free-floating`);
  }

  getServiceHighLight(): Promise<ApiResponse<ServiceHightLight[]>> {
    return this.get(`${this.prefix}/service-highlight`);
  }

  getSubPromo(): Promise<ApiResponse<string>> {
    return this.get(`${this.prefix}/subs-promo`);
  }

  getCubabeliPolicies(): Promise<ApiResponse<CubabeliPolicy[]>> {
    return this.get(`${this.prefix}/cubabeli-policies`);
  }

  getCountry(): Promise<ApiResponse<any>> {
    return this.get(`${this.prefix}/country`)
  }

  getMidBanners(): Promise<ApiResponse<any>> {
    return this.get(`${this.prefix}/home-announcement`)
  }

  getHomeBlogs(): Promise<ApiResponse<any[]>> {
    return this.get(`${this.prefix}/home-blogs`)
  }
}
