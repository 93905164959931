import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { IonButton } from '@ionic/angular';

@Directive({
  selector: 'ion-button'
})
export class DisableButtonDirective {

  // default delay 1s for click button;
  @Input() delay = 1;
  constructor(
    private element: ElementRef<IonButton>
  ) { }

  @HostListener('click')
  click() {
    this.element.nativeElement.disabled = true;

    setTimeout(() => {
      this.element.nativeElement.disabled = false;
    }, this.delay * 1000);
  }
}
