import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ROUTES } from 'src/shared/common/app-routes';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  data: any
  params: any
  CACHED_PARAM_PREFIX = 'cachedParams-';

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.params = params
    });
  }

  navigate(url: string, data?: any) {
    this.data = data
    this.router.navigate([url])
    this.scrollToTop()
  }

  navigateWithParams(url: string, params: any, data?: any) {
    this.data = data
    this.router.navigate([url], {
      queryParams: {
        ...params
      }
    })
    this.scrollToTop()
  }

  navigateByUrl(url: string, data?: any) {
    this.data = data
    this.router.navigateByUrl(url)
  }

  // prevent back to previous page
  navigateAndReplace(url: string, data?: any) {
    this.data = data
    this.router.navigate([url], { replaceUrl: true })
    this.scrollToTop()
  }

  // prevent back to previous page
  navigateWithParamsAndReplace(url: string, params: any, data?: any) {
    this.data = data
    this.router.navigate([url], {
      replaceUrl: true,
      queryParams: {
        ...params
      }
    })
    this.scrollToTop()
  }

  // prevent back to previous page
  navigateByUrlAndReplace(url: string, data?: any) {
    this.data = data
    this.router.navigateByUrl(url, { replaceUrl: true })
  }

  // update url + parames without reload
  updateParams(params: any) {
    this.location.go(this.router.createUrlTree([], { queryParams: params }).toString())
  }

  back(data?: any) {
    this.data = data;
    this.location.back();
    this.scrollToTop()
  }

  goHome(data?: any) {
    this.data = data;
    this.router.navigate(['/home'], { replaceUrl: true })
    this.scrollToTop()
  }

  // auto back to previous url
  goToLoginPage() {
    const params = {
      redirectUrl: window?.location.pathname,
      search: JSON.stringify(this.params)
    }
    this.navigateWithParams(APP_ROUTES.AUTHENTICATION, params)
  }

  getParams() {
    return this.params
  }

  getData(key: string) {
    if (!this.data) {
      return null
    }
    return this.data[key];
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  // store query params in session storage with key is current page
  async storeQueryParams(page: string) {
    return new Promise((resolve, reject) => {
      //get current query params
      const subs = this.activatedRoute.queryParams.subscribe((params: any) => {
        sessionStorage.setItem(this.CACHED_PARAM_PREFIX + page, JSON.stringify(params))
        resolve(true);
        subs.unsubscribe();
      })
    })
  }

  // get query params from session storage with key is current page
  getCachedQueryParams(page: string) {
    return JSON.parse(sessionStorage.getItem(this.CACHED_PARAM_PREFIX + page) || '{}')
  }

}
