import { Injectable } from '@angular/core';;
declare var window: any;
import { environment as configENV } from '../../environments/environment';
import { initializeApp } from "firebase/app";
import { Platform } from '@ionic/angular';
import { CONSTANTS } from '../common/constants';
import { getRemoteConfig, fetchAndActivate, getString } from "firebase/remote-config";

@Injectable()
export class AppInitService {

  constructor(
    private platform: Platform
  ) { }

  public init() {
    return new Promise(async res => {
      const config = {
        ...configENV.firebaseConfig,
        appId: configENV.firebaseAppId.replace('{os}', this.platform.is('ios') ? 'ios' : 'android')
      }
      let clonedFirebaseApp = initializeApp(config, "clone");
      const remoteConfig = getRemoteConfig(clonedFirebaseApp);

      console.log("remoteConfig ========> ", remoteConfig)

      remoteConfig.settings.minimumFetchIntervalMillis = configENV.minimumFetchIntervalMillis

      try {
        await fetchAndActivate(remoteConfig);
      } catch (error) {
        console.log("fetchAndActivate ERROR ==========>", error)
        res(true)
      }

      if (configENV.production) {
        let config: any = getString(remoteConfig, configENV.versionConfig)
        config = config ? JSON.parse(config) : {}
        console.log("remoteConfig =====> ", config)
        if (config instanceof Object) {
          for (const key in config) {
            // @ts-ignore
            configENV[key] = config[key]
          }
        }
        console.log("USING ENVIROMENT =====> ", configENV)
      }

      this.loadRemoteConfig(remoteConfig)
      res(true)
    })
  }

  async loadRemoteConfig(remoteConfig: any) {
    function loadScript(key: string, src: string) {
      if (!src) {
        console.warn('loadScript: src is empty');
        return;
      }

      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      // add callback function for Maps API to load
      if (key === 'google_maps_api_url') {
        // Attach your callback function to the `window` object
        window['initMap'] = function () {
          // JS API is loaded and available
          console.log('Google Maps API loaded');
        };
        script.async = true;
      }
      document.body.appendChild(script);
    }

    const keys = Object.keys(CONSTANTS.DEFAULT_EXT_SOURCE);
    try {
      // load remote config
      keys.forEach((key) => {
        const src = getString(remoteConfig, key);
        console.log(`remoteConfig ${key} ====>`, src)
        // build script tag dynamically and append to body of index.html
        loadScript(key, src);
      })
    } catch (error) {
      console.log('loadRemoteConfig error', error);
      // load default config
      keys.forEach((key) => {
        // @ts-ignore
        loadScript(key, CONSTANTS.DEFAULT_EXT_SOURCE[key]);
      })
    } finally {
      if (remoteConfig) {
        // remoteConfig.delete().then(() => {
        //   console.log('cloned firebaseApp deleted')
        // }).catch((err: any) => {
        //   console.log('cloned firebaseApp delete error', err);
        // });
      }
    }
  }
}