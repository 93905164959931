import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment'
import { CONSTANTS } from 'src/shared/common/constants';
import { ConfigService } from 'src/shared/services/config/config.service';
import { FiltersService } from 'src/shared/services/fiters/filters.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  longTermPricingPackages: any[] = [];
  selectePackage: any = {}
  @Output() onDateChange: EventEmitter<any> = new EventEmitter()

  @Input() selectionMode: any = 'single';
  @Input() numberOfMonth = 1;
  @Input() timeOnly: boolean = false;
  @Input() hourFormat: string = '12';
  @Input() dateSelected?: any;
  @Input() showLongTermPricingPackage = false;
  minDate = new Date();
  maxDate = new Date(moment().add('months', 3).toString())
  constructor(
    private filtersService: FiltersService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    let startDate = this.filtersService.getStartDate()
    let endDate = this.filtersService.getEndDate()

    this.dateSelected = [new Date(startDate), new Date(endDate)]
    this.getLongTermPricingPackage();
    this.updateDateSelectedLayout();
    this.disabledSelectyearBtn();
    this.checkNavBtn(startDate);
  }

  async getLongTermPricingPackage() {
    try {
      const result = await this.configService.getLongTermPricingPackages();
      this.longTermPricingPackages = result && result.data || [];
    } catch (error) {
      console.error(error)
    }
  }

  onSelectPackage(item: any) {
    if (this.selectionMode !== 'range') {
      return;
    }

    this.selectePackage = item;

    const ranges = this.dateSelected as Date[];
    const start = ranges[0] || new Date();
    let end = moment(start).add(item.minDaySelect, 'days').format(CONSTANTS.BOOKING_DATE_FORMAT);

    if (moment(end).diff(moment(this.maxDate), 'days') > 0) {
      end = moment(this.maxDate).format(CONSTANTS.BOOKING_DATE_FORMAT);
    }

    this.dateSelected = [start, new Date(end)];
    this.onDateChange.emit(this.dateSelected);

    this.updateDateSelectedLayout();
    this.disabledSelectyearBtn();
    this.checkNavBtn(start);
  }


  selectDate(event: any) {
    if (this.selectePackage) {
      this.selectePackage = undefined;
    }
    this.onDateChange.emit(this.dateSelected)
    this.updateDateSelectedLayout();
  }

  updateDateSelectedLayout() {
    setTimeout(() => {
      //remove old start/end/range date
      const startDate = document.querySelectorAll('span.start-date:not(.p-disabled)');
      startDate.forEach(item => {
        item.classList.remove('start-date');
      })
      const endDate = document.querySelectorAll('span.end-date:not(.p-disabled)');
      endDate.forEach(item => {
        item.classList.remove('end-date');
      })
      const rangeDate = document.querySelectorAll('span.range-date:not(.p-disabled)');
      rangeDate.forEach(item => {
        item.classList.remove('range-date');
      })

      const newRange = document.querySelectorAll('span.p-highlight:not(.p-disabled)');
      // console.log('range: ', newRange);

      if (!newRange.length || newRange.length == 1) {
        return;
      }

      newRange.forEach((item, key) => {
        if (key == 0) {
          return item.classList.add('start-date');
        }

        if (key == (newRange.length - 1)) {
          return item.classList.add('end-date');
        }

        item.classList.add('range-date');
      })
    }, 0);
  }

  checkNavBtn(startDate: any) {
    const startMonth = new Date(startDate).getMonth() + 1;
    const minMonth = this.minDate.getMonth() + 1;
    const maxMonth = this.maxDate.getMonth() + 1;

    if (startMonth === minMonth) {
      this.disableNavBtn('p-datepicker-prev');
    }

    if (startMonth === maxMonth) {
      this.disableNavBtn('p-datepicker-next');
    }
  }

  disabledSelectyearBtn() {
    this.disableNavBtn('p-datepicker-year');
    this.disableNavBtn('p-datepicker-month');
  }

  disableNavBtn(btnName = '') {
    setTimeout(() => {
      const btnuttonEl = document.getElementsByClassName(btnName) || [];
      const btn = btnuttonEl && btnuttonEl[0];
  
      btn?.setAttribute("disabled", 'true');
    },0)
  }

  monthChange(event: any) {
    this.updateDateSelectedLayout();
    this.disabledSelectyearBtn();
    const minMonth = this.minDate.getMonth() + 1;
    const maxMonth = this.maxDate.getMonth() + 1;

    if (minMonth == event.month) {
      this.disableNavBtn('p-datepicker-prev')
    }

    if (maxMonth == event.month) {
      this.disableNavBtn('p-datepicker-next')
    }
  }
}
