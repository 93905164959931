import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SharedModule } from 'src/shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx';
import { DeviceMotion } from '@awesome-cordova-plugins/device-motion/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { GlobalErrorHandlerService } from 'src/shared/services/global-error-handler.service';
import { GlobalService } from 'src/shared/services/global.service';
import { NavService } from 'src/shared/services/nav/nav.service';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { LoadingComponent } from 'src/shared/components/loading/loading.component';
import { LoadingService } from 'src/shared/services/loading/loading.service';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { NetworkService } from 'src/shared/native_services/network/network.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { FiltersService } from 'src/shared/services/fiters/filters.service';
import { FaceApiService } from 'src/shared/services/face-api/faceapi.service';
import { GoogleCloudVisionService } from 'src/shared/services/google-cloud-vision-service';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireRemoteConfigModule } from '@angular/fire/compat/remote-config';
import { environment as configENV } from '../environments/environment';
import { AppInitService } from 'src/shared/services/app-init.service';
import { FCMPluginOnIonic } from 'cordova-plugin-fcm-with-dependecy-updated/ionic'; // REMOVED /ngx
import { FcmService } from 'src/shared/native_services/FCM/fcm.service';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { LocalPushNotificationService } from 'src/shared/native_services/FCM/local-push-notification.service';

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(configENV.firebaseConfig),
    AngularFireRemoteConfigModule
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Camera,
    Geolocation,
    InAppBrowser,
    SpinnerDialog,
    GooglePlus,
    CameraPreview,
    DeviceMotion,
    GlobalErrorHandlerService,
    GlobalService,
    NavService,
    LaunchNavigator,
    StatusBar,
    AppVersion,
    BluetoothLE,
    LoadingService,
    Network,
    NetworkService,
    Device,
    OpenNativeSettings,
    FileTransfer,
    FileOpener,
    File,
    PhotoViewer,
    FiltersService,
    FaceApiService,
    GoogleCloudVisionService,
    Clipboard,
    AngularFireRemoteConfigModule,
    FCMPluginOnIonic,
    FcmService,
    LocalNotifications,
    LocalPushNotificationService
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }

export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}