import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/shared/services/loading/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  isLoading: boolean = false
  timeout: number = 30 // default timeout loading 30s

  intervalId: string | number | NodeJS.Timeout | undefined
  constructor(
    private gocarLoadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.gocarLoadingService._showLoading.subscribe((result: any) => {
      this.isLoading = result.state
      let t = this
      if (this.isLoading) {
        let timeout = result.duration || this.timeout
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
        this.intervalId = window.setInterval(function () {
          console.log("s")
          timeout = timeout - 1
          if (timeout == 0) {
            t.isLoading = false
            if (t.intervalId)
              clearInterval(t.intervalId);
          }
        }, 1000);
      } else {
        if (this.intervalId)
          clearInterval(this.intervalId);
      }
    });
  }
}
