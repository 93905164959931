import { Injectable } from '@angular/core';
import { ModalController, ModalOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: any[] = []
  private modal: any
  data: any
  constructor(
    public modalCtrl: ModalController
  ) { }

  public async showModal(component: any, data?: any) {
    this.data = data || null;
    return new Promise(async (resolve, reject) => {
      this.modal = await this.modalCtrl.create({
        component: component,
        componentProps: data
      })
      await this.modal.present()
      this.modal.onDidDismiss().then((result: any) => {
        resolve(result.data)
      })
    })
  }

  public async showModalWithOption(option: ModalOptions, data?: any) {
    this.data = data || null;
    return new Promise(async (resolve, reject) => {
      this.modal = await this.modalCtrl.create(option)
      await this.modal.present()
      this.modal.onDidDismiss().then((result: any) => {
        resolve(result.data)
      })
    })
  }

  public async showPopUpWithoutDismiss(component: any, data?: any) {
    this.data = data || null;
    let modal = await this.modalCtrl.create({
      component: component,
      cssClass: ['height-auto', 'ion-padding-20', 'border-radius-10'],
      backdropDismiss: false
    });
    return modal.present()
  }

  public currentModal() {
    return this.modal
  }

  public hideModal(data?: any) {
    // if (this.modals.length > 0) {
    //     this.modals[this.modals.length - 1].dismiss()
    //     this.modals.pop()
    // }
    if (this.modalCtrl) {
      this.modalCtrl.dismiss(data);
    }

  }

  get(key: string) {
    if (!this.data) {
      return null
    }
    return this.data[key];
  }

  getTop() {
    return this.modalCtrl.getTop()
  }
}
