import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from 'src/shared/common/app-routes';
import { DemoComponent } from 'src/shared/components/demo/demo.component';
import { AuthGuard } from 'src/shared/guard/auth.guard';

const routes: Routes = [
  {
    path: APP_ROUTES.HOME,
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: APP_ROUTES.BOOKINGS.HOME,
    loadChildren: () => import('./my-booking/my-booking.module').then(m => m.MyBookingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: APP_ROUTES.WALLET,
    loadChildren: () => import('./wallet/wallet.module').then(m => m.WalletPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: APP_ROUTES.USER,
    loadChildren: () => import('./user/user.module').then(m => m.UserPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: APP_ROUTES.PROMO,
    loadChildren: () => import('./promo/promo.module').then(m => m.PromoModule)
  },
  {
    path: APP_ROUTES.AUTHENTICATION,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: APP_ROUTES.RESERVATIONS,
    loadChildren: () => import('./reservations/reservations.module').then(m => m.ReservationsModule)
  },
  {
    path: APP_ROUTES.REPORT_ACCIDENT.GET_STARTED,
    loadChildren: () => import('./report-accident/report-accident.module').then(m => m.ReportAccidentModule)
  },
  {
    path: 'demo',
    component: DemoComponent
  },
  {
    path: '',
    redirectTo: APP_ROUTES.HOME,
    pathMatch: 'full'
  },
  {
    path: APP_ROUTES.MAILBOX.HOME,
    loadChildren: () => import('./mailbox/mailbox.module').then(m => m.MailboxModule),
    canActivate: [AuthGuard]
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
