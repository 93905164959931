import { Injectable } from '@angular/core';
import { Camera, CameraOptions, DestinationType, EncodingType, MediaType } from '@awesome-cordova-plugins/camera/ngx';
import { CONSTANTS } from 'src/shared/common/constants';
import { GlobalService } from 'src/shared/services/global.service';

@Injectable({
    providedIn: 'root'
})

/*
* DOCUMENT: https://ionicframework.com/docs/native/camera
*/

export class CameraService {
    private win: any = window;
    configCamera: any = {}
    public options: CameraOptions = {
        destinationType: DestinationType.FILE_URL,
        encodingType: EncodingType.JPEG,
        correctOrientation: true,
        saveToPhotoAlbum: false,
        mediaType: MediaType.PICTURE,
        targetHeight: CONSTANTS.IMAGE_HEIGHT,
        quality: 50
    }
    constructor(
        public camera: Camera,
        private globalService: GlobalService,
    ) {
        this.configCamera = this.globalService.getConfigGlobal('configCamera') || {}
        if (this.configCamera && this.configCamera.quality) {
            this.options.quality = this.configCamera.quality
        }
    }

    /**
     * 
     * @param sourceType 0 = photo library, 1 = camera
     */
    async initCamera(sourceType?: number, cameraDirection?: number): Promise<string> {
        this.options.sourceType = sourceType
        this.options.cameraDirection = cameraDirection || 0
        console.log("initCamera ===> ", this.options)
        const result = await this.camera.getPicture(this.options)
        const uri = await this.win.Ionic.WebView.convertFileSrc(result);
        return uri;
    }
}
