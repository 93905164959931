import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as config } from '../../environments/environment'

@Injectable()
export class GoogleCloudVisionService {
    constructor(public http: HttpClient) { }

    getTextDetection(base64Image: string) {
        const body = {
            "requests": [
                {
                    "image": {
                        "content": base64Image
                    },
                    "features": [
                        {
                            "type": "TEXT_DETECTION",
                            "maxResults": 1
                        }
                    ]
                }
            ]
        }
        return this.http.post('https://vision.googleapis.com/v1/images:annotate?key=' + config.googleCloudVisionAPIKey, body);
    }
}