import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FCMPluginOnIonic } from 'cordova-plugin-fcm-with-dependecy-updated/ionic'; // REMOVED /ngx
import { Platform } from '@ionic/angular';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { UserService } from 'src/shared/services/user/user.service';
import { CONSTANTS } from 'src/shared/common/constants';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { NavService } from 'src/shared/services/nav/nav.service';
import { GlobalService } from 'src/shared/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  configFCM: any

  constructor(
    private fcm: FCMPluginOnIonic,
    private platform: Platform,
    private device: Device,
    private userService: UserService,
    private localNotifications: LocalNotifications,
    private navService: NavService,
    private globalService: GlobalService
  ) { }

  async init() {
    this.configFCM = this.globalService.getConfigGlobal('fcm')
    console.log("configFCM: ", this.configFCM)

    if (!this.configFCM || !this.configFCM.isActivePushNotification || !this.platform.is('cordova')) {
      return
    }

    this.hasPermission()
    this.requestPushPermission()
    this.onNotification()
    this.registerFcmToken()
    this.onTokenRefresh()

    const getInitialPushPayload = await this.fcm.getInitialPushPayload()
    console.log("getInitialPushPayload: ", getInitialPushPayload)
    if (getInitialPushPayload && getInitialPushPayload['router']) {
      const router = JSON.parse(getInitialPushPayload['router'])
      if (router) {
        this.navService.navigate(router.url, router.data)
      }
    }

    // request for local notification plugin
    const localNotificationsHasPermission = await this.localNotifications.hasPermission()
    console.log("localNotificationsHasPermission: ", localNotificationsHasPermission)
    const localNotificationsRequestPemission = await this.localNotifications.requestPermission()
    console.log("localNotificationsRequestPemission: ", localNotificationsRequestPemission)
  }

  async registerFcmToken() {
    if (!this.configFCM || !this.configFCM.isActivePushNotification) {
      return
    }
    try {
      const token = await this.fcm.getToken()
      console.log("getToken ======>: ", token)
      if (token && localStorage.getItem(CONSTANTS.GOCAR_TOKEN)) {
        const data = {
          fcmToken: token,
          model: this.device.model,
          platform: this.device.platform,
          uuid: this.device.uuid,
          version: this.device.version,
          isVirtual: this.device.isVirtual,
          serial: this.device.serial,
          sdkVersion: this.device.sdkVersion
        }
        localStorage.setItem(CONSTANTS.FCM_TOKEN, token)
        await this.userService.createOrUpdateFCMToken(data)
        console.log("createOrUpdateFCMToken success!")
      }
    } catch (error: any) {
      console.log("registerFcmToken error: ", error)
    }
  }

  async onTokenRefresh() {
    try {
      console.log("onTokenRefresh =====>: ")
      this.fcm.onTokenRefresh().subscribe(async token => {
        console.log(token)
        if (token && localStorage.getItem(CONSTANTS.GOCAR_TOKEN)) {
          const data = {
            fcmToken: token,
            model: this.device.model,
            platform: this.device.platform,
            uuid: this.device.uuid,
            version: this.device.version,
            isVirtual: this.device.isVirtual,
            serial: this.device.serial,
            sdkVersion: this.device.sdkVersion
          }
          localStorage.setItem(CONSTANTS.FCM_TOKEN, token)
          await this.userService.createOrUpdateFCMToken(data)
          console.log("onTokenRefresh update success!")
        }
      })
    } catch (error) {
      console.log("onTokenRefresh error: ", error)
    }
  }

  async onNotification() {
    this.fcm.onNotification().subscribe(async (notification: any) => {
      if (notification.wasTapped) {
        console.log("onNotification Received in background: ", notification);
        const router = notification && notification.router && JSON.parse(notification.router)
        if (router) {
          this.navService.navigate(router.url, router.data)
        }
      } else {
        console.log("onNotification Received in foreground: ", JSON.stringify(notification));
        if (!this.configFCM || !this.configFCM.isActiveLocalPushNotification) {
          return
        }
        try {
          if (this.device.platform == "iOS") {
            const gMessageId = notification['gcm.message_id']
            if (gMessageId) {
              let isPresent = await this.localNotifications.isPresent(gMessageId)
              if (!isPresent) {
                this.localPush(gMessageId, notification)
              }
            }
          } else {
            this.localPush(0, notification)
          }
        } catch (error) {
          console.log("localNotifications schedule ====> ", error)
        }
      }
    });
  }

  localPush(id: any, data: any) {
    this.localNotifications.schedule({
      id: id,
      title: data && data.title,
      text: data && data.body,
      data: data,
      foreground: true
    });

    this.localNotifications.on('click').subscribe(notification => {
      console.log("local push notification click =====> : ", notification);
      const data = notification && notification.data
      const router = data && data.router && JSON.parse(data.router)
      if (router) {
        this.navService.navigate(router.url, router.data)
      }
    })
  }

  async hasPermission() {
    try {
      const result = await this.fcm.hasPermission()
      console.log('onNotification hasPermission: ', result)
      return true
    } catch (error) {
      console.log('onNotification hasPermission error: ', error)
      return false
    }
  }

  async requestPushPermission() {
    try {
      const result = await this.fcm.requestPushPermission({
        ios9Support: {
          timeout: 10,  // How long it will wait for a decision from the user before returning `false`
          interval: 0.3 // How long between each permission verification
        }
      })
      console.log("requestPushPermission: ", result)
      return true
    } catch (error) {
      console.log("requestPushPermission error: ", error)
      return false
    }
  }
}