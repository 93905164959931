import { Component, OnInit } from '@angular/core';
import { CameraService } from 'src/shared/native_services/camera/camera.service';
import { LoadingService } from 'src/shared/services/loading/loading.service';
import { PopoverService } from 'src/shared/services/popover/popover.service';
import * as moment from 'moment'
import { PopupRentalOptionComponent } from 'src/shared/components/popup-rental-option/popup-rental-option.component';
import { ModalService } from 'src/shared/services/modal/modal.service';
import { ToastService } from 'src/shared/services/toast/toast.service';
declare let cordova: any;

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnInit {

  constructor(
    private CameraService: CameraService,
    private loadingService: LoadingService,
    private modal: ModalService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
  }

  init() {
    console.log("======> ", window);
    console.log("======> ", cordova);
    console.log("======> ");
    // if (window.cordova.plugins['SJKeyless']) {
    //   let SJKeyless = window.cordova.plugins['SJKeyless']
    //   cordova.exec(this.success, this.error, "SJKeyless", "initSdk", [])
    // } else {
    //   alert('plugin not installed')
    // }
  }

  create_session() {
    // let apiKey = "sjak-AgdY07Hhl3cgrt8NlXLyn9Nz7zjGpLbzIovtdAT4S7o"
    // let vehicle = "SDKT633L_1"
    // if (window.cordova.plugins['SJKeyless']) {
    //   let SJKeyless = window.cordova.plugins['SJKeyless']
    //   // SJKeyless.authrizeSdk([apiKey, vehicle].toString(), this.success, this.error)
    //   cordova.exec(this.success, this.error, "SJKeyless", "authrizeSdk", [apiKey, vehicle])
    // } else {
    //   alert('plugin not installed')
    // }
  }

  door_lock() {
    // if (window.cordova.plugins['SJKeyless']) {
    //   let SJKeyless = window.cordova.plugins['SJKeyless']
    //   cordova.exec(this.success, this.error, "SJKeyless", "doorLockBle", [])
    // } else {
    //   alert('plugin not installed')
    // }
  }

  success(result: any) {
    alert(result);
  }

  error(result: any) {
    alert(result);
  }

  async openCamera() {
    try {
      const res = await this.CameraService.initCamera(0);
      console.log('res:' , res);
    } catch (error) {
      console.log(error);
    }
  }

  async pickImages() {
    try {
      const pick = await this.CameraService.initCamera(2)
      console.log('picl:', pick);
    } catch (error) {
      console.log('error: ', error);
    }
  }

  loading() {
    this.loadingService.showLoading()

    setTimeout(() => {
      this.loadingService.hideLoading()
    }, 2222);
  }

  popup() {
    this.modal.showModalWithOption({
      component: PopupRentalOptionComponent,
      cssClass: ['height-auto'],
      initialBreakpoint: 1,
      breakpoints: [0, 1]
    }).then(result => {
      console.log(result)
    })
  }

  showModal() {
    this.modal.showModal(PopupRentalOptionComponent).then(result => {
      console.log(result)
    })
  }

  // 'warning' | 'success' | 'error'
  showToast(type: string) {
    switch(type) {
      case 'warning': {
        this.toastService.showToastWarning('Warning message');
        break;
      }
      case 'success': {
        this.toastService.showToast('Sucess message');
        break;
      }
      default: 
      this.toastService.showToastError('Error message');

    }
  }
}
