import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toast: any
  constructor(
    private toastCtrl: ToastController
  ) { }

  async showToast(mes: string) {
    if (!mes) {
      return
    }
    const options: ToastOptions = {
      message: mes,
      duration: 3000,
      cssClass: ['gocar-toast', 'fw-bolder', 'text-12']
    }

    if (this.toast) {
      this.toast.dismiss()
    }
    this.toast = await this.toastCtrl.create(options)
    await this.toast.present()
  }

  async showToastError(mes: string) {
    if (!mes) {
      return
    }
    const options: ToastOptions = {
      message: mes,
      duration: 3000,
      cssClass: ['gocar-toast-error', 'fw-bolder', 'text-12']
    }
    if (this.toast) {
      this.toast.dismiss()
    }
    this.toast = await this.toastCtrl.create(options)
    await this.toast.present()
  }

  async showToastWarning(mes: string) {
    if (!mes) {
      return
    }
    const options: ToastOptions = {
      message: mes,
      duration: 3000,
      cssClass: ['gocar-toast-warning', 'fw-bolder', 'text-12']
    }
    if (this.toast) {
      this.toast.dismiss()
    }
    this.toast = await this.toastCtrl.create(options)
    await this.toast.present()
  }

  hideToast() {
    if (this.toast) {
      this.toast.dismiss()
    }
  }
}
