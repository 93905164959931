<ion-app>
  <div class="network-status" *ngIf="network === NetWorkStatus.OFFLINE">
    <div><strong class="text-16">Your network is weak or offline</strong></div>
    <div class="mt-sm text-14">
      Please check your network settings or <span class="text-underline text-primary"
        (click)="clickTabs(Tabs.HELP)">contact GoCar
        support team</span>
    </div>
  </div>
  <ion-tabs style="position: relative;" id="ion-tabs">
    <div class="help-icon ion-text-end" *ngIf="isShowFAQicon()">
      <img src="./assets/imgs/need-help.png" alt="" class="w-100" (click)="showFAQ()">
    </div>
    <ion-tab-bar slot="bottom">
      <ion-tab-button [ngClass]="{'tab-active': currentTab === Tabs.HOME}" (click)="clickTabs(Tabs.HOME)" mode="ios">
        <ion-ripple-effect></ion-ripple-effect>
        <ion-icon src="./assets/custom-ion-icons/icon-home.svg" *ngIf="currentTab !== Tabs.HOME"></ion-icon>
        <ion-icon src="./assets/custom-ion-icons/icon-home-selected.svg" *ngIf="currentTab === Tabs.HOME"></ion-icon>
        <ion-label class="fw-bolder">Home</ion-label>
      </ion-tab-button>

      <ion-tab-button [ngClass]="{'tab-active': currentTab === Tabs.MY_BOOKING}" (click)="clickTabs(Tabs.MY_BOOKING)" mode="ios">
        <ion-ripple-effect></ion-ripple-effect>
        <ion-icon src="./assets/custom-ion-icons/icon-booking.svg" *ngIf="currentTab !== Tabs.MY_BOOKING"></ion-icon>
        <ion-icon src="./assets/custom-ion-icons/icon-booking-selected.svg" *ngIf="currentTab === Tabs.MY_BOOKING"></ion-icon>
        <ion-label class="fw-bolder">MyBooking</ion-label>
        <ion-badge *ngIf="totalRes">{{totalRes}}</ion-badge>
      </ion-tab-button>

      <ion-tab-button [ngClass]="{'tab-active': currentTab === Tabs.PROMO}" (click)="clickTabs(Tabs.PROMO)" mode="ios">
        <ion-ripple-effect></ion-ripple-effect>
        <ion-icon src="./assets/custom-ion-icons/icon-promo.svg" *ngIf="currentTab !== Tabs.PROMO"></ion-icon>
        <ion-icon src="./assets/custom-ion-icons/icon-promo-selected.svg" *ngIf="currentTab === Tabs.PROMO"></ion-icon>
        <ion-label class="fw-bolder">GoPromo</ion-label>
      </ion-tab-button>

      <ion-tab-button [ngClass]="{'tab-active': currentTab === Tabs.WALLET}" (click)="clickTabs(Tabs.WALLET)" mode="ios">
        <ion-ripple-effect></ion-ripple-effect>
        <ion-icon src="./assets/custom-ion-icons/icon-wallet.svg" *ngIf="currentTab !== Tabs.WALLET"></ion-icon>
        <ion-icon src="./assets/custom-ion-icons/icon-wallet-selected.svg" *ngIf="currentTab === Tabs.WALLET"></ion-icon>
        <ion-label class="fw-bolder">GoPocket</ion-label>
      </ion-tab-button>

      <!-- <ion-tab-button class="tab-home" (click)="clickTabs(Tabs.HOME)">
        <div class="tab-home-custom ion-activatable ripple-parent circle"
          [ngClass]="{'tab-active': currentTab === Tabs.HOME}" (click)="clickTabs(Tabs.HOME)">
          <ion-icon src="./assets/custom-ion-icons/icon-home.svg"></ion-icon>
          <div class="label">
            Home
          </div>
          <ion-ripple-effect class="ripple-color" type="unbounded"></ion-ripple-effect>
        </div>
      </ion-tab-button> -->

      <ion-tab-button (click)="clickTabs(Tabs.USER)" [ngClass]="{'tab-active': currentTab === Tabs.USER}" mode="ios">
        <ion-ripple-effect></ion-ripple-effect>
        <ion-icon src="./assets/custom-ion-icons/icon-help.svg" *ngIf="currentTab !== Tabs.USER"></ion-icon>
        <ion-icon src="./assets/custom-ion-icons/icon-help-selected.svg" *ngIf="currentTab === Tabs.USER"></ion-icon>
        <ion-label class="fw-bolder">Account</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
  <app-loading></app-loading>
</ion-app>