<app-popup-header class="ion-padding-top-20" title="Choose Your Car Rental Option"></app-popup-header>
<div class="ion-padding">
  <ion-radio-group [(ngModel)]="tripTypeSelected">
    <ion-row [ngClass]="{'ion-margin-top-15': i > 0}" *ngFor="let item of rentalOptions; let i = index">
      <ion-col size="12">
        <ion-radio mode="md" labelPlacement="end" [value]="item?.key">
          <div>
            {{item?.name}}
          </div>
          <div style="margin-top: 5px; color: var(--grey2);">
            {{item?.description}}
          </div>
        </ion-radio>
      </ion-col>
    </ion-row>
  </ion-radio-group>

  <ion-row class="ion-margin-top-15">
    <ion-col size="12">
      <ion-button expand="block" (click)="next()">Proceed</ion-button>
    </ion-col>
  </ion-row>
</div>