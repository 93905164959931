import { EventEmitter, Injectable } from "@angular/core";

@Injectable()
export class GlobalService {
    public defaultCity: string = 'Kuala Lumpur'
    configGlobal: any = {}

    _totalRes = new EventEmitter<number>();
    constructor(
    ) { }

    setConfigGlobal(data: any) {
        this.configGlobal = data || {}
    }

    getConfigGlobal(key: string) {
        if (!this.configGlobal) {
            return null
        }
        return this.configGlobal[key];
    }

    emitTotalRes(num: number) {
        this._totalRes.emit(num)
    }
}