
import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { CONSTANTS } from '../../common/constants';
import { LocationService } from 'src/shared/native_services/location/location.service';

@Injectable()
export class FiltersService {

  constructor(
    private locationService: LocationService
  ) {
    this.validateTime();
    // this.initLocation();
  }

  public filter = {
    starDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    location: {}
  };

  async initLocation() {
    const location = await this.locationService.getCurrentLocation()
    if (location) {
      this.filter.location = location
    }
  }

  validateTime() {
    let bookingStartDate = moment().add(1, 'h').minute(0).second(0)
    let bookingEndDate = moment().add(25, 'h').minute(0).second(0)
    this.filter.starDate = bookingStartDate.format(CONSTANTS.BOOKING_DATE_FORMAT).toString()
    this.filter.startTime = bookingStartDate.format(CONSTANTS.BOOKING_HOUR_FORMAT).toString()
    this.filter.endDate = bookingEndDate.format(CONSTANTS.BOOKING_DATE_FORMAT).toString()
    this.filter.endTime = bookingEndDate.format(CONSTANTS.BOOKING_HOUR_FORMAT).toString()

    let startTime = moment(this.getBookingTimeStart(), CONSTANTS.BOOKING_TIME_FORMAT);
    let endTime = moment(this.getBookingTimeEnd(), CONSTANTS.BOOKING_TIME_FORMAT);
    let now = moment().startOf('hours');

    if (startTime.isSameOrAfter(now) == false) {
      startTime = now;
      endTime = endTime.add(1, 'h');
      this.filter.starDate = startTime.format(CONSTANTS.BOOKING_DATE_FORMAT)
      this.filter.endDate = endTime.format(CONSTANTS.BOOKING_DATE_FORMAT)
      this.filter.startTime = startTime.minute(0).second(0).format(CONSTANTS.BOOKING_HOUR_FORMAT)
      this.filter.endTime = endTime.minute(0).second(0).format(CONSTANTS.BOOKING_HOUR_FORMAT)
    }
  }

  setDefaultBookingTime(data: { start: number, end: number }) {
    if (data && data.start && data.end) {
      let bookingStartDate = moment().add(data.start, 'h').minute(0).second(0)
      let bookingEndDate = moment().add(data.end, 'h').minute(0).second(0)
      this.filter.starDate = bookingStartDate.format(CONSTANTS.BOOKING_DATE_FORMAT).toString()
      this.filter.startTime = bookingStartDate.format(CONSTANTS.BOOKING_HOUR_FORMAT).toString()
      this.filter.endDate = bookingEndDate.format(CONSTANTS.BOOKING_DATE_FORMAT).toString()
      this.filter.endTime = bookingEndDate.format(CONSTANTS.BOOKING_HOUR_FORMAT).toString()
    }
  }

  setStartDate(date: string) {
    this.filter.starDate = date;
  }

  setEndDate(date: string) {
    this.filter.endDate = date;
  }

  setStartTime(time: string) {
    this.filter.startTime = time;
  }

  setEndTime(time: string) {
    this.filter.endTime = time;
  }

  getStartDate() {
    // return this.filter.starDate;
    return moment(this.getBookingTimeStart(), CONSTANTS.BOOKING_TIME_FORMAT).format(CONSTANTS.BOOKING_DATE_FORMAT)
  }

  getEndDate() {
    // return this.filter.endDate;
    return moment(this.getBookingTimeEnd(), CONSTANTS.BOOKING_TIME_FORMAT).format(CONSTANTS.BOOKING_DATE_FORMAT)
  }

  getStartTime() {
    // return this.filter.startTime;
    return moment(this.getBookingTimeStart(), CONSTANTS.BOOKING_TIME_FORMAT).format(CONSTANTS.BOOKING_HOUR_MINUTE_FORMAT)
  }

  getEndTime() {
    // return this.filter.endTime;
    return moment(this.getBookingTimeEnd(), CONSTANTS.BOOKING_TIME_FORMAT).format(CONSTANTS.BOOKING_HOUR_MINUTE_FORMAT)
  }

  getBookingTimeStart() {
    return this.filter.starDate.toString() + ' ' + this.filter.startTime.toString()
  }

  getBookingTimeEnd() {
    return this.filter.endDate.toString() + ' ' + this.filter.endTime.toString()
  }

  // NOTE: only using for CAR SHARRING
  setLocation(location: any) {
    this.filter.location = location
  }

  // NOTE: only using for CAR SHARRING
  getLocation() {
    return this.filter.location
  }
}
