// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  moduleUrls: {
    user: "https://gocar-app-api-user-3i65asokvq-as.a.run.app",
    carList: "https://gocar-app-api-car-list-3i65asokvq-as.a.run.app",
    reservation: "https://gocar-app-api-reservation-3i65asokvq-as.a.run.app",
    // user: "http://localhost:8080",
    // carList: "http://localhost:8080",
    // reservation: "http://localhost:8080",
  },
  apiEncryptKey: '7ZuKqj8oHbY3L1V',
  webClientId: "497488104884-19oedt7mphubf8mmrf3v87g84rl7kimo.apps.googleusercontent.com", // using to get idToken login via google (https://github.com/EddyVerbruggen/cordova-plugin-googleplus#8-exchanging-the-idtoken)
  gocar_price_api: "https://staging2.gocar.my/gocar-price-api",
  report_damage_api: "https://staging2.gocar.my/report-damage-api",
  GOCAR_HELPER_API_URL: 'https://staging2.gocar.my/gocar-helper-api',
  GOCAR_HELPER_SECRET_KEY: "gocar_helper_key",
  GOCAR_HELPER_SECRET_VALUE: "2XsE3ydpFukcObeEp6QV8IKJZInhDdmW",
  gogarage_api: "https://garage-api.gocardev.com/gocar-garage-api",
  gocarsubs_api: "https://subs-api.gocardev.com/gocar-subs-management-api",
  cubabeli_api: "https://dev-gocar-cuba-beli-api-3i65asokvq-as.a.run.app",
  googleCloudVisionAPIKey: "AIzaSyD6tIwaSQtaoaNbHtovjENL-uHZs2Up8ro",
  gocar_mailbox_api: "https://staging2.gocar.my/gocar-mailbox-api",
  firebaseConfig: {
    dynamicLink: 'https://qtj58.app.goo.gl/?link=https://www.gocar.my/',
    packageName: 'my.gocar.app',
    apiKey: "AIzaSyApOzc-hcMAyE10uCh3fHX-JZ6UUwEIPC0",
    authDomain: "gocar-staging-917f5.firebaseapp.com",
    databaseURL: "https://gocar-staging-917f5.firebaseio.com",
    projectId: "gocar-staging-917f5",
    storageBucket: "gocar-staging-917f5.appspot.com",
    messagingSenderId: "523687922613"
  },
  firebaseAppId: '1:523687922613:{os}:d06d1a987cffd1c9',
  minimumFetchIntervalMillis: 5 * 60 * 1000, // remote config cache 5m
  versionConfig: "v6"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
