<div class="text-center">
  <p-calendar [(ngModel)]="dateSelected" [inline]="true" [selectionMode]="selectionMode"
    [numberOfMonths]="numberOfMonth" [timeOnly]="timeOnly" [hourFormat]="hourFormat" [showSeconds]="false"
    [minDate]="minDate" [maxDate]="maxDate" (onSelect)="selectDate($event)"
    (onMonthChange)="monthChange($event)">
  </p-calendar>
</div>

<div class="ion-padding-x">
  <hr>
  <div class="ion-margin-top">
    <strong>
      Book longer trip for cheaper rate
    </strong>
  </div>
  <div class="ion-padding-y">
    <div class="package-content d-flex">
      <div *ngFor="let package of longTermPricingPackages"
        class="package-item clickable {{ selectePackage?.id === package.id ? 'package-selected' : ''}}"
        (click)="onSelectPackage(package)">
        <div>
          <strong>{{package.title}}</strong>
        </div>
        <div class="mt-sm">
          <strong>{{package.text}} </strong>
        </div>
      </div>
    </div>
  </div>
</div>