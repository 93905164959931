import { EventEmitter, Injectable } from '@angular/core';
import { IonicSafeString, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  // loading: any;
  // constructor(
  //   private loadingCtr: LoadingController
  // ) { }

  // async showLoading(message?: string, duration?: number) {
  //   if (this.loading) {
  //     return;
  //   }
  //   this.loading = true
  //   let loadingCtr = await this.loadingCtr.create({
  //     message: '<div class="cover-spin"></div>',
  //     spinner: null,
  //     duration: duration || 20000
  //   });
  //   await loadingCtr.present();
  //   if (!this.loading) {
  //     this.hideLoading()
  //   }
  // }

  // async hideLoading() {
  //   this.loading = false
  //   if (this.loadingCtr) {
  //     try {
  //       await this.loadingCtr.dismiss();
  //     } catch (error) {
  //     }
  //   }
  // }

  public _showLoading: EventEmitter<any> = new EventEmitter()

  constructor() { }

  public showLoading(duration?: number) {
    this._showLoading.emit({ state: true, duration: duration });
  }

  public hideLoading() {
    this._showLoading.emit({ state: false });
  }
}
