import { Injectable } from '@angular/core';
import * as faceapi from 'face-api.js';
import { GlobalService } from '../global.service';
import { CONSTANTS } from 'src/shared/common/constants';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class FaceApiService {
  CORS_PROXY_URL = 'https://staging2.gocar.my/cors-proxy'
  configFaceDetection = {
    configInputSize: 320,
    configThreshold: 0.5
  }
  constructor(
    private globalService: GlobalService,
    private userService: UserService
  ) {
    if (this.globalService.getConfigGlobal('CORS_PROXY_URL')) {
      this.CORS_PROXY_URL = this.globalService.getConfigGlobal('CORS_PROXY_URL');
    }
    if (this.globalService.getConfigGlobal('configFaceDetection')) {
      this.configFaceDetection = this.globalService.getConfigGlobal('configFaceDetection');
    }
  }

  async initFaceApi() {
    console.time('TRAIN MODELS');
    await Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('./assets/models'),
      await faceapi.nets.faceLandmark68Net.loadFromUri('./assets/models'),
      await faceapi.nets.faceRecognitionNet.loadFromUri('./assets/models'),
      // await faceapi.nets.faceExpressionNet.loadFromUri('../../assets/models')
    ]).then(async () => {
      try {
        console.timeEnd('TRAIN MODELS');
        this.prepareFaceDetection()
      } catch (error) {
        console.log("initRegisterFace error: ", error)
      }
    });
  }

  prepareFaceDetection() {
    console.time('PREPARE FACE DETECTION');
    let t = this
    let base_image = new Image();
    // base_image.src = "/assets/imgs/upload-document/ic_selfie.png";
    base_image.src = "/assets/imgs/icon-waze.jpg"; // dummy image
    base_image.onload = async function () {
      await faceapi.detectSingleFace(base_image, new faceapi.TinyFaceDetectorOptions({ inputSize: t.configFaceDetection.configInputSize, scoreThreshold: t.configFaceDetection.configThreshold })).withFaceLandmarks().withFaceDescriptor();
      console.timeEnd('PREPARE FACE DETECTION');
    };
  }

  // not using any more
  async initRegisterFace() {
    return new Promise(async (res, rej) => {
      let userInfo
      try {
        console.time('FETCH LATEST SELFIE IMAGE');
        const result = await this.userService.getUserInfo()
        userInfo = result && result.data
      } catch (error) {
        userInfo = this.userService.getUserInfoFromLocalstorage()
      } finally {
        console.timeEnd('FETCH LATEST SELFIE IMAGE');
      }

      if (!userInfo || !userInfo.selfieDir) {
        return rej({ message: "Your selfie image not found. please upload new selfie image." })
      }

      let resisterImage
      try {
        console.time('DOWNLOAD SELFIE IMAGE');
        resisterImage = await faceapi.fetchImage(`${this.CORS_PROXY_URL}/${userInfo.selfieDir}`)
        console.timeEnd('DOWNLOAD SELFIE IMAGE');
      } catch (error) {
        return rej({ message: "Can't get your selfie image." })
      }

      console.time('DETECT FACE OF SELFIE IMAGE');
      const registerFaceDescription = await faceapi.detectSingleFace(resisterImage, new faceapi.TinyFaceDetectorOptions({ inputSize: this.configFaceDetection.configInputSize, scoreThreshold: this.configFaceDetection.configThreshold })).withFaceLandmarks().withFaceDescriptor()
      console.timeEnd('DETECT FACE OF SELFIE IMAGE');
      if (!registerFaceDescription) {
        return rej({ message: "Your selfie image not clear. please upload new selfie image." })
      }

      console.log("register face detected")
      res(true)
    })
  }

  async detectSingleFace(blob: Blob) {
    const img = await faceapi.bufferToImage(blob)
    return faceapi.detectSingleFace(img, new faceapi.TinyFaceDetectorOptions({ inputSize: this.configFaceDetection.configInputSize, scoreThreshold: this.configFaceDetection.configThreshold })).withFaceLandmarks().withFaceDescriptor(); // withFaceExpressions
  }

  async getScore(descriptor1: Float32Array, descriptor2: Float32Array) {
    return faceapi.utils.round(faceapi.euclideanDistance(descriptor1, descriptor2));
  }

  // this function for testing
  async extractFaceTesting(): Promise<any[]> {
    console.time('DOWNLOAD SELFIE IMAGE');
    // const resisterImageUrl = "https://assets.gocar.my/users/6e73ca40-0424-11ee-a30a-27ae03a22f3b/selfie/4021528b-4ac7-450f-afe7-b439926989ad.jpg"
    const resisterImageUrl = 'https://assets.gocar.my/users/2465ec60-6335-11ee-bbb0-65e17d7d1ffa/selfie/227e5ef7-e34a-44c6-a64f-a6a16168f98e.jpg'
    // const resisterImageUrl = 'https://firebasestorage.googleapis.com/v0/b/fir-app-2c3a2.appspot.com/o/users%2F54fec9e2-7bec-405c-8337-290b17ef1a8d%2Fselfie%2F1648008734128-1641544132620-selfie.jpeg?alt=media&token=069e22a5-4472-4bea-8f97-ef4369432817'
    const resisterImage = await faceapi.fetchImage(`${this.CORS_PROXY_URL}/${resisterImageUrl}`)
    console.timeEnd('DOWNLOAD SELFIE IMAGE');

    const registerFaceDescriptions: any = await faceapi.detectSingleFace(resisterImage, new faceapi.TinyFaceDetectorOptions({ inputSize: this.configFaceDetection.configInputSize, scoreThreshold: this.configFaceDetection.configThreshold })).withFaceLandmarks().withFaceDescriptor()
    console.log("registerFaceDescriptions =====> ", registerFaceDescriptions)
    const faceDescriptor1 = await faceapi.extractFaces(resisterImage, [registerFaceDescriptions.detection]);
    let buffer = faceDescriptor1[0].toDataURL('image/jpeg');
    let images = [buffer]

    // let images = []
    // try {
    //   const registerFaceDescriptions = await faceapi.detectAllFaces(resisterImage, new faceapi.TinyFaceDetectorOptions({ inputSize: this.configFaceDetection.configInputSize, scoreThreshold: this.configFaceDetection.configThreshold })).withFaceLandmarks().withFaceDescriptors()
    //   console.log("registerFaceDescriptions =====> ", registerFaceDescriptions)
    //   for (const image of registerFaceDescriptions) {
    //     console.log("image =====> ", image)
    //     const faceDescriptor1 = await faceapi.extractFaces(resisterImage, [image.detection]);
    //     let buffer = faceDescriptor1[0].toDataURL('image/jpeg');
    //     console.log("buffer =====> ", buffer)
    //     images.push(buffer)
    //   }
    // } catch (error) {
    //   console.log(error)
    // }

    return images
  }
}
