import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { BaseService } from '../base.service';
import { NavService } from '../nav/nav.service';
import { ApiResponse } from 'src/shared/models/common';
import { CONSTANTS } from 'src/shared/common/constants';
import { UtilsService } from '../utils/utils.service';
import { AlertController } from '@ionic/angular';
import { User } from 'src/shared/models/user';
import { FcmService } from 'src/shared/native_services/FCM/fcm.service';
import { UserService } from './user.service';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  prefixUrl = 'auth';
  userInfo: any | undefined = undefined;
  userSubject_: EventEmitter<any> = new EventEmitter()

  constructor(
    public override http: HttpClient,
    public override alertController: AlertController,
    private utilsService: UtilsService,
    private navService: NavService,
    private fcmService: FcmService,
    private userService: UserService,
    private globalService: GlobalService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super(http, alertController);
  }

  isAuthenticated(): boolean {
    try {
      const token = localStorage.getItem(CONSTANTS.GOCAR_TOKEN);
      console.log("isAuthenticated =====> ", token)
      if (token) {
        const decoded = jwt_decode(token) as any;
        console.log("decoded =====> ", decoded)
        if (!decoded.exp || Date.now() < decoded.exp * 1000) {
          return true;
        }
      }
      this.clearUserSession();
      return false;
    } catch (error) {
      console.log(error)
      return false;
    }
  }

  clearUserSession() {
    this.utilsService.removeLocalStorage(CONSTANTS.GOCAR_TOKEN);
    this.utilsService.removeLocalStorage(CONSTANTS.USER_INFO);
    this.userSubject_.emit(undefined);
  }

  async login(email: string, password: string): Promise<ApiResponse<any>> {
    const credential = btoa(`${email}:${password}`);
    const res = await this.post<ApiResponse<any>>(`${this.prefixUrl}/login`, null, { Authorization: credential });
    if (res.message == 'Success') {
      this.storeUserSession(res.data);
    }
    return res;
  }

  public isEmailExist(email: string): Promise<boolean> {
    return this.post<ApiResponse<boolean>>(this.prefixUrl + `/users/exists`, { email })
      .then(res => res.data);
  }

  /* incomplete registration on local */
  public saveRegisterInfo(data: Partial<User>) {
    localStorage.setItem(CONSTANTS.REGISTER_INFO, JSON.stringify(data));
  }

  // async changePassword(changePwdForm: ChangePwdForm): Promise<ApiResponse<LoginResponse>> {
  //   const res = await this.post<ApiResponse<LoginResponse>>('public/auth/change-password', changePwdForm);
  //   return res;
  // }

  // /* incomplete registration on local */
  // public saveRegisterInfo(user: Partial<UserInfo>) {
  //   const data = JSON.stringify(user)
  //   this.utilsService.setLocalStorage(CONSTANTS.REGISTER_INFO, data);
  // }

  /**
   * Login via Google or TanChong account
   * @param provider Google or TC
   * @param credential for login via Google, it is token provided by Google, If TC, it is user's password
   * @param email only needed for login via TC account
   * @returns ApiResponse<LoginResponse>
   */
  async loginWithSocial(provider: 'Google' | 'TC_ACCOUNT', credential: string, email?: string): Promise<ApiResponse<any>> {
    const credentialString = btoa(`${provider}:${credential}:${email}`);

    const res = await this.post<ApiResponse<any>>(`${this.prefixUrl}/login-social`, null, { Authorization: credentialString });
    if (res.message == 'Success') {
      this.storeUserSession(res.data);
    }
    return res;
  }

  // verifyGoogleCbToken(token: string) {
  //   return firstValueFrom(this.http.get(`https://oauth2.googleapis.com/tokeninfo?id_token=${token}`));
  // }

  storeUserSession(loginResponse: any) {
    let userBasicInfo: any = {
      userId: loginResponse.id,
      firstName: loginResponse.firstName,
      lastName: loginResponse.lastName,
      email: loginResponse.email,
      telephone: loginResponse.telephone,
      selfieDir: loginResponse.selfieDir
    }
    this.utilsService.setLocalStorage(CONSTANTS.GOCAR_TOKEN, loginResponse.token);
    this.utilsService.setLocalStorage(CONSTANTS.USER_INFO, JSON.stringify(userBasicInfo));
    this.userSubject_.emit(userBasicInfo);
    this.fcmService.registerFcmToken()
  }

  public async logout() {
    console.log('logged out')
    const fcmToken = localStorage.getItem(CONSTANTS.FCM_TOKEN)
    if (fcmToken) {
      this.userService.deleteFCMToken({ fcmToken: fcmToken })
    }

    localStorage.removeItem(CONSTANTS.GOCAR_TOKEN)
    localStorage.removeItem(CONSTANTS.RESERVATION_KEY)
    localStorage.removeItem(CONSTANTS.RESERVATION_RETURN_KEY)
    localStorage.removeItem(CONSTANTS.USER_INFO)
    localStorage.removeItem(CONSTANTS.COUNT_RESERVATION)
    localStorage.removeItem(CONSTANTS.FCM_TOKEN)
    this.globalService.emitTotalRes(0)
  }
}
