
export const APP_ROUTES = {
    HOME: 'home',
    RESERVATIONS: 'reservations',
    BOOKINGS: {
        HOME: 'bookings',
        DETAIL: 'detail',
        TRIP_DETAIL: 'trip-detail',
        CAR_LOCK: 'car-lock-guideline',
        REPORT_DAMAGE: 'report-damage',
        CAR_WASH: 'car-wash',
        EXTEND_TIME: 'extend-timing',
        DROP_OFF_POINT: 'drop-off',
        GOCARSUB_BOOKING_DETAIL: 'gocarsub-booking-detail',
        GOCARSUB_SUBSCRIPTION_DETAIL: 'gocarsub-subscription-detail',
        GOCARSUB_DOCUMENT_TYPE: 'gocarsub-document-type',
        GOCARSUB_UPLOAD_DOCUMENT: 'gocarsub-upload-document',
        GOCARSUB_DONE: 'gocarsub-done',
        GOCARSUB_PAYMENT: 'gocarsub-payment',
        GOCARSUB_BILL_DETAIL: 'gocarsub-bill-detail',
        GOCARSUB_DEBIT: 'gocarsub-debit',
        GOCARSUB_CREDIT_CARD: 'gocarsub-credit-card',
        RATING: 'rating'
    },
    AUTHENTICATION: 'auth',
    USER: 'user',
    PROMO: 'promo',
    WALLET: 'wallet',
    PAYMENT_SUCCESS: 'reservations/payment-success',
    PAYMENT_FAIL: 'reservations/payment-fail',
    REPORT_ACCIDENT: {
        GET_STARTED: 'report-accident',
        UPLOAD_DOCUMENT: 'upload-document',
        ACCIDENT_DETAIL: 'accident-detail',
        THIRD_PARTY_DOCUMENT: 'third-party-document',
        THIRD_PARTY_VEHICLE: 'third-party-vehicle',
        DONE: 'done'
    },
    MAILBOX: {
        HOME: 'mailbox',
        DETAIL: 'detail'
    }
}
